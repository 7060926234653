import { Scenario } from '../types/types';
import { BaseService } from './BaseService';

export class ScenarioService extends BaseService {
  constructor() {
    super();
    this.path = '/scenarios/';
  }

  getScenario = async (scenarioId: string) => {
    return await this.get(`${scenarioId}`);
  };

  saveScenario = async (scenarioId: string, data: Scenario) => {
    return await this.put(`${scenarioId}`, data);
  };
}
