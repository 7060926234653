export const formatPhone = (_phone: string) => {
  const phone = _phone ?? ""
    if (phone.length === 13 && phone.startsWith("55"))
      return `+${phone.substring(0, 2)} ${phone.substring(
        2,
        4
      )} ${phone.substring(4, 9)}-${phone.substring(9, 13)}`;
    else if (phone.length === 12 && phone.startsWith("55"))
      return `+${phone.substring(0, 2)} ${phone.substring(
        2,
        4
      )} ${phone.substring(4, 8)}-${phone.substring(8, 12)}`;
    else if (phone.length === 12 && phone.startsWith("351"))
      return `+${phone.substring(0, 3)} ${phone.substring(
        3,
        6
      )} ${phone.substring(6, 9)} ${phone.substring(9, 12)}`;
    return phone;
  };

export const formatCurrency = (n: number) => {
    return Intl.NumberFormat("pt-BR").format(n);
  };