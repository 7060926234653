import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Subscription } from "../../_shared/types/types";
import { SubscriptionsService } from "../../_shared/services/SubscriptionsService";
import { ProgressBar } from "primereact/progressbar";

interface LoginScreenProps {
  onSubscriptionUpdate: (_subscription: Subscription) => void;
}

export default function LoginScreen(props: LoginScreenProps) {
  const toast: any = useRef(null);
  const [activationCode, setActivationCode] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  const navigate = useNavigate();
  const next = async () => {
    try {
      setProcessing(true);
      const subscription: Subscription = await new SubscriptionsService().getSubscriptionByCode(
        activationCode
      );
      await props.onSubscriptionUpdate(subscription);
      navigate(`/${subscription.id}/scenarios?currentPage=1&perPage=10`);
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      if (error.code === 404) {
        toast?.current?.show({
          severity: "error",
          summary: "Cliente não encontrado",
          detail: "Por favor entre em contato com o nosso time",
        });
      } else {
        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      }
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="flex" style={{ height: "100vh" }}>
        <div
          className="surface-section w-full md:w-6"
          style={{
            padding: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Boas-vindas!
            </div>
            <span className="text-600 font-medium mr-2">
              Informe seu código de cliente
            </span>
          </div>
          <div>
            <InputText
              id="activationCode"
              type="text"
              placeholder="código"
              className="w-full mb-3"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
            />
          </div>
          <div
            className="w-full mb-2"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              disabled={activationCode === ""}
              label="Entrar"
              icon="pi pi-angle-right"
              iconPos="right"
              onClick={next}
            />
          </div>
          {processing && (
            <>
              <div className="w-full">
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              </div>
            </>
          )}
        </div>
        <div
          className="hidden md:block w-6 bg-no-repeat bg-center"
          style={{
            backgroundSize: "180px",
            backgroundColor: "#5371ff",
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/diwo-negative.png')`,
          }}
        ></div>
      </div>
    </>
  );
}
