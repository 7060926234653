/* eslint-disable react/react-in-jsx-scope */
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Scenario } from "../../_shared/types/types";
import { DataProcessService } from "../../_shared/services/DataProcessService";
import MonacoEditor from "@monaco-editor/react";

type SearchPhrase = {
  searchphrase: string;
};

export default function ScenarioUpdateModal(props: {
  selectedScenario: any;
  onScenarioUpdate: (scenario: Scenario) => void;
  onHide: () => void;
  onSave: () => void;
  onCleanTestMessage: () => void;
  processing: boolean;
}) {
  const [loadingSearchPhrases, setLoadingSearchPhrases] =
    useState<boolean>(false);
  const [searchPhrases, setSearchPhrases] = useState<SearchPhrase[]>([]);
  const { onHide, onScenarioUpdate, selectedScenario } = props;
  const toast: any = useRef(null);

  const save = () => {
    if (
      selectedScenario.test_mode &&
      (selectedScenario.test_mode_phone ?? "") === ""
    ) {
      toast?.current?.show({
        severity: "error",
        summary: "Dados Inválidos",
        detail: "Por favor informe o telefone de teste",
      });
    } else {
      props.onSave();
    }
  };

  const loadSearchPhrases = async () => {
    setLoadingSearchPhrases(true);
    const searchPhrasesLoaded = await new DataProcessService().getDataProcess(
      ""
    );
    setSearchPhrases(searchPhrasesLoaded);
    setLoadingSearchPhrases(false);
  };

  useEffect(() => {
    loadSearchPhrases();
  }, []);

  const jsonList =
    '{\n  "sheetData": {\n    "F": "",\n    "G": "",\n    "H": "",\n    "I": "",\n    "J": "",\n    "K": "",\n    "L": "",\n    "M": "",\n    "N": "",\n    "O": ""\n  },\n  "sheetName": "",\n  "spreadsheetId": ""\n}';

  if (selectedScenario.data_source === "GoogleMaps") {
    return (
      <Dialog
        header={selectedScenario.scenario_name}
        visible={true}
        onHide={onHide}
        style={{ minWidth: "50vw", maxWidth: "90vw", height: "80vh" }}
        footer={
          <div>
            {!props.processing && (
              <Button
                disabled={props.processing}
                label="Cancelar"
                icon="pi pi-times"
                onClick={onHide}
                className="p-button-text"
              />
            )}
            <Button
              disabled={props.processing}
              label={props.processing ? "Salvando..." : "Salvar"}
              icon={`pi ${
                props.processing ? "pi-spin pi-spinner" : "pi-check"
              }`}
              onClick={save}
              autoFocus
            />
          </div>
        }
      >
        <Toast ref={toast} />
        <ConfirmDialog />
        <TabView
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <TabPanel header="Geral">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Nome:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.scenario_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, scenario_name: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Nome do SDR:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.sales_rep_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            sales_rep_name: e.target.value,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Filtros">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Termo Pesquisa:
                </div>
                <div
                  className="w-full mb-2"
                  style={{ width: "80% !importante" }}
                >
                  {loadingSearchPhrases ? (
                    <ProgressBar
                      mode="indeterminate"
                      style={{
                        height: "6px",
                        width: "100%",
                        maxWidth: "400px",
                      }}
                    ></ProgressBar>
                  ) : (
                    <Dropdown
                      style={{ width: "100%", maxWidth: "400px" }}
                      value={selectedScenario?.category ?? ""}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              category: e.value,
                            }
                          )
                        )
                      }
                      options={
                        searchPhrases &&
                        searchPhrases.map((searchPhrase: SearchPhrase) => ({
                          id: searchPhrase.searchphrase,
                          name: searchPhrase.searchphrase,
                        }))
                      }
                      optionValue="id"
                      optionLabel="name"
                      placeholder="Selecione ou escreva um termo pesquisa"
                      editable
                    />
                  )}
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Quantidade de Leads por Execução:
                </div>
                <div className="w-full mb-2">
                  <InputNumber
                    disabled={props.processing}
                    value={selectedScenario?.qtd_leads}
                    showButtons
                    onValueChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            qtd_leads: e.value ?? undefined,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Ponto de Partida (Lat/Long):
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.lat_long ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, lat_long: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Filtros Geografia:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.geo_filter ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, geo_filter: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Outros Filtros:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.other_filter ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, other_filter: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="TestMode">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Habilitar Modo de Teste:
                </div>
                <div className="w-full h-full mb-2">
                  <InputSwitch
                    disabled={props.processing}
                    checked={selectedScenario?.test_mode ?? false}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            test_mode: !selectedScenario?.test_mode,
                          }
                        )
                      )
                    }
                  />
                </div>
                {selectedScenario?.test_mode && (
                  <>
                    <div className="font-bold flex align-items-center mb-2">
                      Telefone de Teste:
                    </div>
                    <div className="w-full h-full mb-2">
                      <PhoneInput
                        disabled={props.processing}
                        defaultCountry="BR"
                        placeholder="Informe o telefone de teste"
                        value={selectedScenario?.test_mode_phone ?? ""}
                        onChange={(v) =>
                          onScenarioUpdate(
                            Object.assign(
                              {},
                              {
                                ...selectedScenario,
                                test_mode_phone: v,
                              }
                            )
                          )
                        }
                        style={{ width: "100%", maxWidth: "400px" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="IA">
            <div className="w-full font-medium text-900 flex flex-column mb-2 ml-2">
              <div className="font-bold flex align-items-center mb-2">
                Habilitar IA:
              </div>
              <div className="w-full h-full mb-2">
                <InputSwitch
                  disabled={props.processing}
                  checked={selectedScenario?.bot_active ?? false}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        {
                          ...selectedScenario,
                          bot_active: !selectedScenario?.bot_active,
                        }
                      )
                    )
                  }
                />
              </div>
              {selectedScenario?.bot_active && (
                <>
                  <div className="font-bold flex align-items-center mb-2">
                    Prompt para IA:
                  </div>
                  <div className="w-full h-full mb-2">
                    <InputTextarea
                      disabled={props.processing}
                      value={selectedScenario?.prompt ?? ""}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            { ...selectedScenario, prompt: e.target.value }
                          )
                        )
                      }
                      className="w-full h-full"
                      rows={20}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Memory Window:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_memory_window}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_memory_window: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Qtd máx mensagens:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_max_messages}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_max_messages: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                </>
              )}
            </div>
          </TabPanel>

          <TabPanel header="Integração">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Webhook para integração:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.webhook ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, webhook: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  }

  if (selectedScenario.data_source === "List") {
    return (
      <Dialog
        header={selectedScenario.scenario_name}
        visible={true}
        onHide={onHide}
        style={{ minWidth: "50vw", maxWidth: "90vw", height: "80vh" }}
        footer={
          <div>
            {!props.processing && (
              <Button
                disabled={props.processing}
                label="Cancelar"
                icon="pi pi-times"
                onClick={onHide}
                className="p-button-text"
              />
            )}
            <Button
              disabled={props.processing}
              label={props.processing ? "Salvando..." : "Salvar"}
              icon={`pi ${
                props.processing ? "pi-spin pi-spinner" : "pi-check"
              }`}
              onClick={save}
              autoFocus
            />
          </div>
        }
      >
        <Toast ref={toast} />
        <ConfirmDialog />
        <TabView
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <TabPanel header="Geral">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Nome:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.scenario_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, scenario_name: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Nome do SDR:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.sales_rep_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            sales_rep_name: e.target.value,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Fonte">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                {/* <div className="font-bold flex align-items-center mb-2">
                  SpreadsheetId:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.config?.spreadshetId ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            config: {
                              ...selectedScenario.config,
                              spreadshetId: e.target.value,
                            },
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div> */}
                {/* <div className="font-bold flex align-items-center mb-2">
                  SheetName:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.config?.sheetname ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            config: {
                              ...selectedScenario.config,
                              sheetname: e.target.value,
                            },
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div> */}
                <div className="font-bold flex align-items-center mb-2">
                  Quantidade de Leads por Execução:
                </div>
                <div className="w-full mb-2">
                  <InputNumber
                    disabled={props.processing}
                    value={selectedScenario?.qtd_leads}
                    showButtons
                    onValueChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            qtd_leads: e.value ?? undefined,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Mapeamento:
                </div>
                <div className="w-full mb-2">
                  <div
                    className="w-full mb-2"
                    style={{ height: "300px", border: "1px solid #ccc" }}
                  >
                    <MonacoEditor
                      height="100%"
                      defaultLanguage="json"
                      value={
                        selectedScenario?.config
                          ? JSON.stringify(selectedScenario.config, null, 2)
                          : ""
                      }
                      defaultValue={jsonList}
                      onChange={(value) => {
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              config: value
                                ? JSON.parse(value)
                                : selectedScenario.config,
                            }
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="IA">
            <div className="w-full font-medium text-900 flex flex-column mb-2 ml-2">
              <div className="font-bold flex align-items-center mb-2">
                Habilitar IA:
              </div>
              <div className="w-full h-full mb-2">
                <InputSwitch
                  disabled={props.processing}
                  checked={selectedScenario?.bot_active ?? false}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        {
                          ...selectedScenario,
                          bot_active: !selectedScenario?.bot_active,
                        }
                      )
                    )
                  }
                />
              </div>
              {selectedScenario?.bot_active && (
                <>
                  <div className="font-bold flex align-items-center mb-2">
                    Prompt para IA:
                  </div>
                  <div className="w-full h-full mb-2">
                    <InputTextarea
                      disabled={props.processing}
                      value={selectedScenario?.prompt ?? ""}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            { ...selectedScenario, prompt: e.target.value }
                          )
                        )
                      }
                      className="w-full h-full"
                      rows={20}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Memory Window:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_memory_window}
                      showButtons
                      onValueChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_memory_window: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Qtd máx mensagens:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_max_messages}
                      showButtons
                      onValueChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_max_messages: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                </>
              )}
            </div>
          </TabPanel>

          <TabPanel header="Integração">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Webhook para integração:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.webhook ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, webhook: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  }

  if (selectedScenario.data_source === "CRM") {
    return (
      <Dialog
        header={selectedScenario.scenario_name}
        visible={true}
        onHide={onHide}
        style={{ minWidth: "50vw", maxWidth: "90vw", height: "80vh" }}
        footer={
          <div>
            {!props.processing && (
              <Button
                disabled={props.processing}
                label="Cancelar"
                icon="pi pi-times"
                onClick={onHide}
                className="p-button-text"
              />
            )}
            <Button
              disabled={props.processing}
              label={props.processing ? "Salvando..." : "Salvar"}
              icon={`pi ${
                props.processing ? "pi-spin pi-spinner" : "pi-check"
              }`}
              onClick={save}
              autoFocus
            />
          </div>
        }
      >
        <Toast ref={toast} />
        <ConfirmDialog />
        <TabView
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <TabPanel header="Geral">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Nome:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.scenario_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, scenario_name: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Nome do SDR:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.sales_rep_name ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            sales_rep_name: e.target.value,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="CRM">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  CRM:
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    style={{ width: "100%", maxWidth: "400px" }}
                    value={selectedScenario?.config?.crm ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            config: {
                              ...selectedScenario.config,
                              crm: e.value,
                            },
                          }
                        )
                      )
                    }
                    options={[
                      {
                        id: "pipedrive",
                        name: "Pipedrive",
                      },
                      {
                        id: "hubspot",
                        name: "Hubspot",
                      },
                    ]}
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Selecionar CRM"
                  />
                </div>
                {/* <div className="font-bold flex align-items-center mb-2">
                  User Token:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.config?.usertoken ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            config: {
                              ...selectedScenario.config,
                              usertoken: e.target.value,
                            },
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div> */}
                {/* <div className="font-bold flex align-items-center mb-2">
                  Mapeamento:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.config?.mapping ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            config: {
                              ...selectedScenario.config,
                              mapping: e.target.value,
                            },
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div> */}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="IA">
            <div className="w-full font-medium text-900 flex flex-column mb-2 ml-2">
              <div className="font-bold flex align-items-center mb-2">
                Habilitar IA:
              </div>
              <div className="w-full h-full mb-2">
                <InputSwitch
                  disabled={props.processing}
                  checked={selectedScenario?.bot_active ?? false}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        {
                          ...selectedScenario,
                          bot_active: !selectedScenario?.bot_active,
                        }
                      )
                    )
                  }
                />
              </div>
              {selectedScenario?.bot_active && (
                <>
                  <div className="font-bold flex align-items-center mb-2">
                    Prompt para IA:
                  </div>
                  <div className="w-full h-full mb-2">
                    <InputTextarea
                      disabled={props.processing}
                      value={selectedScenario?.prompt ?? ""}
                      onChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            { ...selectedScenario, prompt: e.target.value }
                          )
                        )
                      }
                      className="w-full h-full"
                      rows={20}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Memory Window:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_memory_window}
                      showButtons
                      onValueChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_memory_window: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                  <div className="font-bold flex align-items-center mb-2">
                    Qtd máx mensagens:
                  </div>
                  <div className="w-full mb-2">
                    <InputNumber
                      disabled={props.processing}
                      value={selectedScenario?.prompt_max_messages}
                      showButtons
                      onValueChange={(e) =>
                        onScenarioUpdate(
                          Object.assign(
                            {},
                            {
                              ...selectedScenario,
                              prompt_max_messages: e.value ?? undefined,
                            }
                          )
                        )
                      }
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </div>
                </>
              )}
            </div>
          </TabPanel>

          <TabPanel header="Integração">
            <div>
              <div className="w-12 font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Webhook para integração:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    disabled={props.processing}
                    value={selectedScenario?.webhook ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, webhook: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  }

  return (
    <Dialog
      header={selectedScenario.scenario_name}
      visible={true}
      onHide={onHide}
      style={{ minWidth: "50vw", maxWidth: "90vw", height: "80vh" }}
      footer={
        <div>
          {!props.processing && (
            <Button
              disabled={props.processing}
              label="Cancelar"
              icon="pi pi-times"
              onClick={onHide}
              className="p-button-text"
            />
          )}
          <Button
            disabled={props.processing}
            label={props.processing ? "Salvando..." : "Salvar"}
            icon={`pi ${props.processing ? "pi-spin pi-spinner" : "pi-check"}`}
            onClick={save}
            autoFocus
          />
        </div>
      }
    >
      <Toast ref={toast} />
      <ConfirmDialog />
      <TabView
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          minWidth: "400px",
          width: "100%",
        }}
      >
        <TabPanel header="Geral">
          <div>
            <div className="w-12 font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Nome:
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.scenario_name ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        { ...selectedScenario, scenario_name: e.target.value }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Termo Pesquisa:
              </div>
              <div className="w-full mb-2" style={{ width: "80% !importante" }}>
                {loadingSearchPhrases ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px", width: "100%", maxWidth: "400px" }}
                  ></ProgressBar>
                ) : (
                  <Dropdown
                    style={{ width: "100%", maxWidth: "400px" }}
                    value={selectedScenario?.category ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            category: e.value,
                          }
                        )
                      )
                    }
                    options={
                      searchPhrases &&
                      searchPhrases.map((searchPhrase: SearchPhrase) => ({
                        id: searchPhrase.searchphrase,
                        name: searchPhrase.searchphrase,
                      }))
                    }
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Selecione ou escreva um termo pesquisa"
                    editable
                  />
                )}
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Nome do SDR:
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.sales_rep_name ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        {
                          ...selectedScenario,
                          sales_rep_name: e.target.value,
                        }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Quantidade de Leads por Execução:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  disabled={props.processing}
                  value={selectedScenario?.qtd_leads}
                  showButtons
                  onValueChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        {
                          ...selectedScenario,
                          qtd_leads: e.value ?? undefined,
                        }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Ponto de Partida (Lat/Long):
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.lat_long ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        { ...selectedScenario, lat_long: e.target.value }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Filtros Geografia:
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.geo_filter ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        { ...selectedScenario, geo_filter: e.target.value }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Outros Filtros:
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.other_filter ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        { ...selectedScenario, other_filter: e.target.value }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
              <div className="font-bold flex align-items-center mb-2">
                Webhook para integração:
              </div>
              <div className="w-full mb-2">
                <InputText
                  disabled={props.processing}
                  value={selectedScenario?.webhook ?? ""}
                  onChange={(e) =>
                    onScenarioUpdate(
                      Object.assign(
                        {},
                        { ...selectedScenario, webhook: e.target.value }
                      )
                    )
                  }
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Modo de Teste">
          <div className="w-full font-medium text-900 flex flex-column mb-2 ml-2">
            <div className="font-bold flex align-items-center mb-2">
              {(selectedScenario.test_messages_count ?? 0) > 0 && (
                <Message
                  className="border-primary w-full justify-content-start"
                  severity="warn"
                  content={
                    <div className="flex align-items-center">
                      <i className="pi pi-info-circle"></i>
                      <div className="ml-2">
                        Existe
                        {(selectedScenario.test_messages_count ?? 0) > 1
                          ? "m"
                          : ""}{" "}
                        {selectedScenario.test_messages_count} mensage
                        {(selectedScenario.test_messages_count ?? 0) > 1
                          ? "ns"
                          : "m"}{" "}
                        de teste na base para este cenário.{" "}
                        <span
                          className={
                            props.processing
                              ? "opacity-50"
                              : "cursor-pointer underline"
                          }
                          onClick={() => {
                            if (!props.processing) {
                              props.onCleanTestMessage();
                            }
                          }}
                        >
                          <b>Apagar</b>
                        </span>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
            <div className="font-bold flex align-items-center mb-2">
              Habilitar Modo de Teste:
            </div>
            <div className="w-full h-full mb-2">
              <InputSwitch
                disabled={props.processing}
                checked={selectedScenario?.test_mode ?? false}
                onChange={(e) =>
                  onScenarioUpdate(
                    Object.assign(
                      {},
                      {
                        ...selectedScenario,
                        test_mode: !selectedScenario?.test_mode,
                      }
                    )
                  )
                }
              />
            </div>
            {selectedScenario?.test_mode && (
              <>
                <div className="font-bold flex align-items-center mb-2">
                  Telefone de Teste:
                </div>
                <div className="w-full h-full mb-2">
                  <PhoneInput
                    disabled={props.processing}
                    defaultCountry="BR"
                    placeholder="Informe o telefone de teste"
                    value={selectedScenario?.test_mode_phone ?? ""}
                    onChange={(v) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            test_mode_phone: v,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel header="IA">
          <div className="w-full font-medium text-900 flex flex-column mb-2 ml-2">
            <div className="font-bold flex align-items-center mb-2">
              Habilitar IA:
            </div>
            <div className="w-full h-full mb-2">
              <InputSwitch
                disabled={props.processing}
                checked={selectedScenario?.bot_active ?? false}
                onChange={(e) =>
                  onScenarioUpdate(
                    Object.assign(
                      {},
                      {
                        ...selectedScenario,
                        bot_active: !selectedScenario?.bot_active,
                      }
                    )
                  )
                }
              />
            </div>
            {selectedScenario?.bot_active && (
              <>
                <div className="font-bold flex align-items-center mb-2">
                  Prompt para IA:
                </div>
                <div className="w-full h-full mb-2">
                  <InputTextarea
                    disabled={props.processing}
                    value={selectedScenario?.prompt ?? ""}
                    onChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          { ...selectedScenario, prompt: e.target.value }
                        )
                      )
                    }
                    className="w-full h-full"
                    rows={20}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Memory Window:
                </div>
                <div className="w-full mb-2">
                  <InputNumber
                    disabled={props.processing}
                    value={selectedScenario?.prompt_memory_window}
                    showButtons
                    onValueChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            prompt_memory_window: e.value ?? undefined,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div className="font-bold flex align-items-center mb-2">
                  Qtd máx mensagens:
                </div>
                <div className="w-full mb-2">
                  <InputNumber
                    disabled={props.processing}
                    value={selectedScenario?.prompt_max_messages}
                    showButtons
                    onValueChange={(e) =>
                      onScenarioUpdate(
                        Object.assign(
                          {},
                          {
                            ...selectedScenario,
                            prompt_max_messages: e.value ?? undefined,
                          }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </>
            )}
          </div>
        </TabPanel>
      </TabView>
    </Dialog>
  );
}
