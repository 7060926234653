import { BaseService } from "./BaseService";
import axios from "axios";

export class EntityService extends BaseService {
  entity: string;
  constructor(entity: string) {
    super();
    this.entity = entity;
  }

  cancelAllRequests = async () => {
    // Cancel all requests
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    source.cancel("Operation canceled by the user.");
  };

  load = async (id: string): Promise<any> => {
    return await this.get(`/entities/${this.entity}/${id}`);
  };

  listAll = async (search?: string): Promise<any> => {
    return await this.get(
      `/entities/${this.entity}?all=1${search ? `&q=${search}` : ""}`
    );
  };

  list = async (
    page: number,
    rowsPerPage: number,
    sortField: string,
    sortOrder: 0 | 1 | -1,
    search: string
  ): Promise<any> => {
    return await this.get(
      `/entities/${this.entity}?p=${page},${rowsPerPage}&s=${sortField},${sortOrder}&q=${search}`
    );
  };

  save = async (id: string, data: any): Promise<any> => {
    if (id === "") return await this.post(`/entities/${this.entity}`, data);
    else return await this.put(`/entities/${this.entity}/${id}`, data);
  };

  create = async (data: any): Promise<any> => {
    return await this.post(`/entities/${this.entity}`, data);
  };

  update = async (id: string, data: any): Promise<any> => {
    return await this.put(`/entities/${this.entity}/${id}`, data);
  };

  updateEngageBot = async (id: string, data: any): Promise<any> => {
    return await this.put(`/entities-engage/${this.entity}/${id}`, data);
  };

  delete = async (id: string): Promise<any> => {
    return await this.del(`/entities/${this.entity}/${id}`);
  };

  getUploadURL = async (
    id: string,
    propertie: string,
    fileName: string,
    type: string
  ) => {
    return await this.post(`/entities/${this.entity}/${id}/upload-url `, {
      propertie,
      fileName,
      type,
    });
  };

  upload = async (url: string, file: any, onProgress: (value: any) => void) => {
    const options = {
      params: { Key: file.name, ContentType: file.type },
      headers: { "Content-Type": file.type },
      onUploadProgress: (progressEvent: any) => {
        const percentage = (progressEvent.loaded * 100) / progressEvent.total;
        if (onProgress) {
          onProgress(percentage);
        }
      },
    };

    await axios.put(url, file, options);
  };
}
