import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 60%;
  margin: 0 auto;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const InputTextStyled = styled(InputText)`
  width: 100%;
  min-width: 328px;
`;

export const InputTextareaStyled = styled(InputTextarea)`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  align-items: end;
`;
