import { Subscription } from "../types/types";
import { BaseService } from "./BaseService";

export class SubscriptionsService extends BaseService {
  constructor() {
    super();
    this.path = "/subscriptions/";
  }

  list = async (): Promise<Subscription[]> => {
    return (await this.get("")) as Subscription[];
  };

  create = async (dealId: string): Promise<void> => {
    await this.postToMake(
      "https://hook.us1.make.com/4797yns1kqcs454z6ygov6l6ocq3owji",
      { dealid: dealId }
    );
    return;
  };

  cancel = async (subscriptionId: string): Promise<void> => {
    await this.postToMake(
      "https://hook.us1.make.com/v564rafb4xbs8nryyed51acnr1qyehga",
      { subscriptionid: subscriptionId }
    );
    return;
  };

  createScenario = async (
    subscriptionId: string,
    name: string,
    data_source: string
  ): Promise<void> => {
    await this.postToMake(
      "https://hook.us1.make.com/ma3pvy909tyv1c5gj4yghgt5ah2zecvn",
      { subscriptionid: subscriptionId, name, data_source }
    );
    return;
  };

  deleteScenario = async (
    subscriptionId: string,
    scenarioId: string
  ): Promise<void> => {
    (await this.put(
      `${subscriptionId}/scenarios/${scenarioId}/delete`,
      {}
    )) as Subscription;
    return;
  };

  getSubscriptionByCode = async (
    code: string,
    currentPage?: number,
    perPage?: number
  ): Promise<Subscription> => {
    const params = `currentPage=${currentPage ?? ""}&perPage=${perPage ?? ""}`;
    return (await this.get(code, params)) as Subscription;
  };

  getBillingData = async (id: string) => {
    return await this.get(`${id}/billing`);
  };

  getCreditStatement = async (
    id: string,
    page?: number,
    rowsPerPage?: number,
    search?: string,
    sortField?: string,
    sortDirection?: 0 | -1 | 1
  ) => {
    return await this.get(
      `${id}/credits-statement?p=${page ?? ""}&pp=${rowsPerPage ?? ""}&f=${
        search ?? ""
      }&sf=${sortField ?? "2"}&sd=${sortDirection ?? -1}`
    );
  };

  getPlaceCreditsStatement = async (
    subscriptionId: string,
    placeId: string
  ) => {
    return await this.get(`${subscriptionId}/credits-statement/${placeId}`);
  };

  saveScenario = async (
    subscriptionrId: string,
    scenarioId: string,
    updatedScenario: any
  ): Promise<Subscription> => {
    return (await this.put(
      `${subscriptionrId}/scenarios/${scenarioId}`,
      updatedScenario
    )) as Subscription;
  };

  pauseScenario = async (
    subscriptionrId: string,
    scenarioId: string
  ): Promise<Subscription> => {
    return (await this.put(
      `${subscriptionrId}/scenarios/${scenarioId}/pause`,
      {}
    )) as Subscription;
  };

  unpauseScenario = async (
    subscriptionrId: string,
    scenarioId: string
  ): Promise<Subscription> => {
    return (await this.put(
      `${subscriptionrId}/scenarios/${scenarioId}/unpause`,
      {}
    )) as Subscription;
  };

  executeScenario = async (
    subscriptionrId: string,
    scenarioId: string
  ): Promise<Subscription> => {
    return (await this.put(
      `${subscriptionrId}/scenarios/${scenarioId}/execute`,
      {}
    )) as Subscription;
  };

  getScenarioQRCode = async (subscriptionrId: string, scenarioId: string) => {
    return await this.get(`${subscriptionrId}/scenarios/${scenarioId}/qrcode`);
  };

  checkScenarioInstanceConnection = async (
    subscriptionrId: string,
    scenarioId: string
  ) => {
    return await this.get(
      `${subscriptionrId}/scenarios/${scenarioId}/check-connection`
    );
  };

  disconnectScenarioInstance = async (
    subscriptionrId: string,
    scenarioId: string
  ) => {
    return await this.get(
      `${subscriptionrId}/scenarios/${scenarioId}/disconnect`
    );
  };

  clearScenarioTestMessages = async (
    subscriptionrId: string,
    scenarioId: string
  ) => {
    return await this.get(
      `${subscriptionrId}/scenarios/${scenarioId}/delete-test-messages`
    );
  };
}
