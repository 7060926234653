import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";

import { SetStateAction, useEffect, useState } from "react";
import { Subscription, BillingData } from "../../_shared/types/types";
import { SubscriptionsService } from "../../_shared/services/SubscriptionsService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";

interface BillingScreenProps {
  subscription: Subscription;
}

type TotalByType = {
  service: string;
  amount: number;
};

export default function CreditStatement(props: BillingScreenProps) {
  const [processing, setProcessing] = useState<boolean>(true);

  const formatCurrency = (n: number) => {
    return Intl.NumberFormat("pt-BR").format(n);
  };

  const [billingData, setBillingData] = useState<BillingData[] | undefined>(
    undefined
  );

  const [totals, setTotals] = useState<TotalByType[] | undefined>(undefined);
  const [selected, setSelected] = useState<any | undefined>(undefined);

  // Pagination, Filter and Sort
  const [search, setSearch] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [reloading, setReloading] = useState<boolean>(false);
  const [tempSearch, setTempSearch] = useState<string>("");
  const [sort, setSort] = useState<{ field: string, direction: 0 | 1 | -1 }>({ field: "2", direction: -1 })

  const onPageChange = async (pageEvent: {
    first: number;
    page: number;
    rows: number;
  }) => {
    setReloading(true);
    setPage(pageEvent.page);
    setRowsPerPage(pageEvent.rows);
  };
  // ------

  useEffect(() => {
    const loadBilling = async () => {
      try {
        setReloading(true);
        const response: {
          billingData: BillingData[];
          totals: TotalByType[];
          totalRecords: number;
        } = await new SubscriptionsService().getCreditStatement(
          props.subscription.id,
          page,
          rowsPerPage,
          search,
          sort.field,
          sort.direction
        );
        setBillingData(response.billingData);
        setTotals(response.totals);
        setTotalRecords(response.totalRecords);
        setProcessing(false);
        setReloading(false);
      } catch (error) {
        console.log(error);
      }
    };

    loadBilling();
  }, [props.subscription.id, page, rowsPerPage, search, sort]);

  const navigate = useNavigate();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    setReloading(true);

    const response = await new SubscriptionsService().getCreditStatement(
      props.subscription.id
    );

    const ws = XLSX.utils.json_to_sheet(
      (response.billingData ?? []).map((i: any) => ({
        v1: i.place_id,
        v2: moment(i.timestamp).format("YYYY-MM-DD HH:mm"),
        v3: i.name,
        v4: i.phone,
        v5: i.searchphrase,
        v6: i.category,
        v7: i.website,
        v8: i.endereco,
        v9: i.bairro,
        v10: i.cidade,
        v11: i.uf,
        v12: i.reviews,
        v13: i.score,
        v14: i.mapurl,
        v15: i.service,
        v16: parseFloat(i.amount ?? 0),
      }))
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "ID",
          "Data e Hora",
          "Nome",
          "Telefone",
          "Termo de Pesquisa",
          "Categoria",
          "Website",
          "Endereco",
          "Bairro",
          "Cidade",
          "UF",
          "Reviews",
          "Score",
          "GoogleMaps URL",
          "Serviço",
          "Valor",
        ],
      ],
      { origin: "A1" }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "maplead" + fileExtension);
    setReloading(false);
  };

  const formatDate = (date: string) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
  };

  const getTotal = (serviceid: string) => {
    return formatCurrency(
      (
        totals
          ?.filter((t) => t.service === serviceid || serviceid === "total")
          .map((s: any) => parseFloat(s.amount)) ?? []
      ).reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      )
    );
  };

  const selectPlace = async (id: string) => {
    setReloading(true);
    const response: BillingData =
      await new SubscriptionsService().getPlaceCreditsStatement(
        props.subscription.id,
        id
      );
    setSelected(response);
    setReloading(false);
  };

  return (
    <>
      <div className="p-4 w-full">
        <div className="flex justify-content-between align-items-center mb-5 w-full">
          <div className="text-xl text-900 font-medium">
            Detalhamento de Consumo
            {props.subscription.status === "inactive" && (
              <Tag
                severity="warning"
                value="Subscrição Inativa"
                className="ml-4"
              ></Tag>
            )}
          </div>
          <div>
            <Button
              label="Voltar para Cenários"
              icon="pi pi-chevron-left"
              outlined
              onClick={() => navigate(`/${props.subscription.id}/scenarios?currentPage=1&perPage=10`)}
            />
            <InputText
              value={tempSearch}
              onChange={(e) => setTempSearch(e.target.value)}
              style={{ marginLeft: "10px" }}
              placeholder="Buscar"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  setReloading(true);
                  setSearch(tempSearch);
                }
              }}
            />
            <Button
              icon="pi pi-search"
              onClick={() => {
                setReloading(true);
                setSearch(tempSearch);
              }}
              style={{ marginLeft: "2px" }}
            />
            <Button
              label="Exportar"
              icon="pi pi-file-excel"
              onClick={() => exportToExcel()}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
        {processing && (
          <>
            <div className="w-full mb-2">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </>
        )}
        {billingData && (
          <>
            <DataTable
              value={billingData || []}
              tableStyle={{ minWidth: "50rem" }}
              loading={reloading}
              // scrollable
              // scrollHeight="400px"
              onSort={(e) => { setPage(0); setSort({ field: e.sortField, direction: e.sortOrder ?? -1 })}}
              sortField={sort.field} sortOrder={sort.direction}
            >
              <Column
                field="timestamp"
                header="Data e Hora"
                body={(row: any) =>
                  moment(row.timestamp).format("YYYY-MM-DD HH:mm")
                }
                style={{ whiteSpace: "nowrap" }}
                footer={<b>Total: {totalRecords}</b>}
                sortField="2"
                sortable={true}
                
              ></Column>
              <Column
                field="name"
                header="Nome"
                body={(row: any) => (
                  <Button
                    label={row.name}
                    link
                    onClick={() => selectPlace(row.place_id)}
                    style={{ textAlign: "left" }}
                  />
                )}
                sortField="3"
                sortable={true}
              ></Column>
              <Column
                header="Telefone"
                field="phone"
                style={{ whiteSpace: "nowrap" }}
                sortField="4"
                sortable={true}
              ></Column>
              <Column
                header="Termo Pesquisa"
                field="searchphrase"
                sortField="6"
                sortable={true}
              ></Column>
              <Column
                header="Categoria"
                field="category"
                sortField="5"
                sortable={true}
              ></Column>
              <Column
                header="Serviço"
                field="service"
                sortField="7"
                sortable={true}
              ></Column>
              <Column
                header="Valor"
                field="amount"
                footer={getTotal("total")}
                headerStyle={{ textAlign: "right" }}
                style={{ textAlign: "right" }}
                sortField="8"
                sortable={true}
              ></Column>
            </DataTable>
            <Paginator
              first={page * rowsPerPage}
              rows={rowsPerPage}
              totalRecords={totalRecords}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onPageChange={onPageChange}
            ></Paginator>
            {selected && (
              <Dialog
                header={selected.name}
                visible={true}
                onHide={() => {
                  setSelected(undefined);
                }}
                style={{ minWidth: "60vw" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "100px" }}>
                    <b>Categoria</b>:{" "}
                    {selected.services[0].data.place.categoria}
                    <br />
                    <br />
                    <b>Telefone</b>: {selected.services[0].data.place.telefone}
                    <br />
                    <br />
                    <b>Website</b>:{" "}
                    <a
                      href={selected.services[0].data.place.website}
                      target="_new"
                    >
                      {selected.services[0].data.place.website}
                    </a>
                    <br />
                    <br />
                    <b>Endereco</b>: <br />
                    {selected.services[0].data.place.endereco}
                    <br />
                    <br />
                    <b>Reviews</b>: {selected.services[0].data.place.reviews}
                    <br />
                    <br />
                    <b>Score</b>: {selected.services[0].data.place.score}
                    <br />
                    <br />
                    <b>GoogleMaps</b>:{" "}
                    <a
                      href={selected.services[0].data.place.mapurl}
                      target="_new"
                    >
                      clique aqui
                    </a>
                  </div>
                  <DataTable
                    scrollable
                    scrollHeight="400px"
                    value={
                      selected.services.sort((a: any, b: any) =>
                        a.timestamp > b.timestamp ? 1 : -1
                      ) || []
                    }
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field="timestamp"
                      header="Data/Hora"
                      body={(row: any) => formatDate(row.timestamp)}
                    ></Column>
                    <Column
                      field="data.service.servicename"
                      header="Servico"
                    ></Column>
                    <Column
                      field="amount"
                      header="Valor"
                      footer={formatCurrency(
                        selected.services
                          .map((s: any) => s.amount)
                          .reduce(
                            (accumulator: number, currentValue: number) =>
                              accumulator + currentValue,
                            0
                          )
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </Dialog>
            )}
          </>
        )}
      </div>
    </>
  );
}
