import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";

import { useEffect, useState } from "react";
import {
  Subscription,
  BillingData,
} from "../../_shared/types/types";
import { SubscriptionsService } from "../../_shared/services/SubscriptionsService";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

interface BillingScreenProps {
  subscription: Subscription;
}

export default function BillingScreen(props: BillingScreenProps) {
  const [processing, setProcessing] = useState<boolean>(true);

  const formatCurrency = (n: number) => {
    return Intl.NumberFormat("pt-BR").format(n);
  };

  const [billingData, setBillingData] = useState<BillingData[] | undefined>(
    undefined
  );

  useEffect(() => {
    const loadBilling = async () => {
      try {
        const _billindData: BillingData[] =
          await new SubscriptionsService().getBillingData(
            props.subscription.id
          );
        setBillingData(_billindData);
        setProcessing(false);
      } catch (error) {
        console.log(error);
      }
    };

    loadBilling();
  }, [props.subscription.id]);

  const navigate = useNavigate();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      (billingData ?? []).map((i: BillingData) => ({
        v1: moment(i.consumptionDate).format("DD/MM/YYYY"),
        v2: i.place.placeid,
        v3: i.place.nome,
        v4: i.place.categoria,
        v5: i.place.telefone,
        v6: i.place.website,
        v7: i.place.endereco,
        v8: i.place.bairro,
        v9: i.place.cidade,
        v10: i.place.uf,
        v11: i.place.reviews,
        v12: i.place.score,
        v13: i.place.mapurl,
        v14: parseFloat(i.captureCredits ?? 0),
        v15: parseFloat(i.whatsappCheckCredits ?? 0),
        v16: parseFloat(i.messageSentCredits ?? 0),
      }))
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Data",
          "ID",
          "Nome",
          "Categoria",
          "Telefone",
          "Website",
          "Endereco",
          "Bairro",
          "Cidade",
          "UF",
          "Reviews",
          "Score",
          "GoogleMaps URL",
          "Captura",
          "Verificação Whatsapp",
          "Envio de Mensagem",
        ],
      ],
      { origin: "A1" }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "maplead" + fileExtension);
  };

  const formatDate = (date: string) => {
    const tokens: string[] = date.split("T")[0].split("-")
    return tokens[2] + "/" + tokens[1] + "/" + tokens[0]
  }

  return (
    <>
      <div className="p-4 w-full">
        <div className="flex justify-content-between align-items-center mb-5 w-full">
          <div className="text-xl text-900 font-medium">
            Detalhamento de Consumo
            {props.subscription.status === "inactive" && (
              <Tag
                severity="warning"
                value="Subscrição Inativa"
                className="ml-4"
              ></Tag>
            )}
          </div>
          <div>
            <Button
              label="Voltar para Cenários"
              icon="pi pi-chevron-left"
              outlined
              onClick={() => navigate(`/${props.subscription.id}/scenarios?currentPage=1&perPage=10`)}
            />
            <Button
              label="Exportar"
              icon="pi pi-file-excel"
              onClick={() => exportToExcel()}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
        {processing && (
          <>
            <div className="w-full mb-2">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </>
        )}
        {billingData && (
          <>
            <DataTable
              value={billingData || []}
              tableStyle={{ minWidth: "50rem" }}
              scrollable
              scrollHeight="400px"
            >
              <Column
                field="consumptionDate"
                header="Data"
                body={(data: BillingData) =>
                  formatDate(data.consumptionDate)
                }
              ></Column>
              <Column field="place.nome" header="Nome"></Column>
              <Column field="place.categoria" header="Categoria"></Column>
              <Column
                field="place.telefone"
                header="Telefone"
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="captureCredits"
                header="Captura"
                style={{ textAlign: "center" }}
                footer={formatCurrency(billingData.map((b: BillingData) => parseFloat(b.captureCredits)).reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                ))}
              ></Column>
              <Column
                field="whatsappCheckCredits"
                header="Verificação Whatsapp"
                style={{ textAlign: "center" }}
                footer={formatCurrency(billingData.map((b: BillingData) => parseFloat(b.whatsappCheckCredits)).reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                ))}
              ></Column>
              <Column
                field="messageSentCredits"
                header="Envio de Mensagem"
                style={{ textAlign: "center" }}
                footer={formatCurrency(billingData.map((b: BillingData) => parseFloat(b.messageSentCredits)).reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                ))}
              ></Column>
              <Column
                body={(billingData: BillingData) => parseFloat(billingData.captureCredits) + parseFloat(billingData.whatsappCheckCredits) + parseFloat(billingData.messageSentCredits)}
                header="Total"
                style={{ textAlign: "center", fontWeight: 'bolder' }}
                footer={ formatCurrency(billingData.map((b: BillingData) => parseFloat(b.captureCredits) + parseFloat(b.whatsappCheckCredits) + parseFloat(b.messageSentCredits)).reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                ))}
              ></Column>
            </DataTable>
          </>
        )}
      </div>
    </>
  );
}
