/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, useEffect, useRef, useState } from "react";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { EntityService } from "../../services/EntityService";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressBar } from "primereact/progressbar";
import { InputSwitch } from "primereact/inputswitch";
import { InstancesService } from "../../services/InstancesServices";

type EntityListColumn = {
  title: string;
  body: string | ((obj: any) => ReactNode);
  sortable?: boolean;
  sortField?: string;
};

type EntityListProps = {
  entity: string;
  entityLabel?: string;
  title: string;
  columns: EntityListColumn[];
  defaultSortField?: string;
  defaultSortOrder?: 0 | 1 | -1;
  createDisabled?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  hideDetailsButton?: boolean;
  hideRefresh?: boolean;
  extraButtons?: {
    icon: string;
    label: string;
    onClick: (obj?: any) => void;
    className?: string;
    perItem?: boolean;
    showIf?: (obj: any) => boolean;
  }[];
  onEdit?: (obj?: any) => void;
  summary?: {
    highlightdIndexes?: number[];
    sortable?: boolean;
    sortField?: string;
  };
  search?: string[];
  AddComponent?: (
    entityInstance: any,
    onEntityUpdate: (updatedEntityInstance: any) => void
  ) => JSX.Element;
  EditComponent?: (
    entityInstance: any,
    onEntityUpdate: (updatedEntityInstance: any) => void
  ) => JSX.Element;
  ViewComponent?: (entityInstance: any) => JSX.Element;
};

export default function EntityList(props: EntityListProps) {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [list, setList] = useState<any[]>([]);
  const [sortField, setSortField] = useState<string>(
    props.defaultSortField ?? "id"
  );
  const [sortOrder, setSortOrder] = useState<0 | 1 | -1>(
    props.defaultSortOrder ?? 0
  );
  const [searchText, setSearchText] = useState<string>("");
  const [partialSearchText, setPartialSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const loadList = async () => {
    setLoading(true);
    const service: EntityService = new EntityService(props.entity);
    const result = await service.list(
      page,
      rowsPerPage,
      sortField,
      sortOrder,
      searchText === "" ? "" : props.search?.join("*") + "=" + searchText
    );
    setList(result.items);
    setTotal(result.totalRecords);
    setLoading(false);
  };
  useEffect(() => {
    loadList();
  }, [page, rowsPerPage, sortField, sortOrder, searchText]);

  let searchTimeout: any;
  const updateSearchText = (value: string) => {
    clearTimeout(searchTimeout);
    setPartialSearchText(value);
    searchTimeout = setTimeout(() => setSearchText(value), 2000);
  };

  const AddComponent = props.AddComponent;
  const [showAddOrEditDialog, setShowAddOrEditDialog] =
    useState<boolean>(false);
  const [entityInstanceToAddOrEdit, setEntityInstanceToAddOrEdit] =
    useState<any>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  const toast: any = useRef(null);

  const save = async () => {
    if (entityInstanceToAddOrEdit) {
      try {
        setSaving(true);
        const service = new EntityService(props.entity);
        await service.save(
          entityInstanceToAddOrEdit.id ?? "",
          entityInstanceToAddOrEdit
        );
        await loadList();
        setSaving(false);
        setEntityInstanceToAddOrEdit(undefined);
        setShowAddOrEditDialog(false);
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Item saved",
        });
      } catch (error: any) {
        setSaving(false);
        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      }
    }
  };

  const deleteInstance = async (id: string) => {
    if (id) {
      try {
        setSaving(true);
        const service = new EntityService(props.entity);
        await service.delete(id);
        await loadList();
        setSaving(false);
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Item deleted",
        });
      } catch (error: any) {
        setSaving(false);
        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      }
    }
  };

  const updateEntity = async (entity: any) => {
    try {
      setSaving(true);
      const service = new InstancesService();
      console.log(entity);
      await service.updateInstace(entity.instance, {
        ...entity,
        subscribed: !entity.subscribed,
      });
      await loadList();
      setSaving(false);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Item updated",
      });
    } catch (error: any) {
      setSaving(false);
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: error.message,
      });
    }
  };

  const executeExtraButtonAction = async (action: (obj?: any) => void) => {
    try {
      setSaving(true);
      await action();
      await loadList();
      setSaving(false);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Action triggered",
      });
    } catch (error: any) {
      setSaving(false);
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: error.message,
      });
    }
  };

  const renderColumnBody = (
    column: {
      title: any;
      body: any;
      sortable?: boolean | undefined;
      sortField?: string | undefined;
    },
    data: any
  ) => {
    if (column.title === "Subscribed") {
      return (
        <InputSwitch
          checked={data.subscribed}
          onChange={() => {
            return updateEntity(data);
          }}
        />
      );
    }

    return (
      <div
        style={props.editDisabled ? {} : { cursor: "pointer", width: "100%" }}
        onClick={
          props.editDisabled
            ? () => {}
            : () => {
                if (props.onEdit) {
                  props.onEdit(data);
                } else {
                  navigate(data.id);
                }
              }
        }
      >
        {(column.body as any)(data)}
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="surface-section px-4 py-3 md:px-4 lg:px-4 border-round">
        <DataTable
          value={list}
          sortMode="single"
          onSort={(e: DataTableStateEvent) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder ?? 0);
          }}
          loading={loading}
          sortField={sortField}
          sortOrder={sortOrder ?? 0}
          header={
            <>
              <div className="x-header">
                <div className="x-header-title">
                  <div className="font-medium text-3xl text-900">
                    {props.title}
                  </div>
                </div>
                <div className="x-header-buttons">
                  {props.search && (
                    <>
                      <div className="x-header-buttons-search">
                        <InputText
                          placeholder="Search"
                          value={partialSearchText}
                          onChange={(e) => setPartialSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setSearchText(partialSearchText);
                            }
                          }}
                        />
                        <Button
                          icon="pi pi-search"
                          label="Search"
                          tooltip="Search"
                          tooltipOptions={{ position: "top" }}
                          style={{ marginLeft: "10px" }}
                          onClick={() => setSearchText(partialSearchText)}
                        />
                      </div>
                    </>
                  )}

                  {(props.extraButtons ?? [])
                    .filter((b) => !b.perItem)
                    .map((btn, i) => (
                      <Button
                        key={`header_extra_button_${i}`}
                        icon={btn.icon}
                        aria-label={btn.label}
                        label={btn.label}
                        tooltip={btn.label}
                        tooltipOptions={{ position: "top" }}
                        title={btn.label}
                        onClick={() => executeExtraButtonAction(btn.onClick)}
                        style={{ marginLeft: "10px" }}
                      />
                    ))}

                  {!props.hideRefresh && (
                    <Button
                      icon="pi pi-sync"
                      label="Refresh"
                      tooltip="Refresh"
                      tooltipOptions={{ position: "top" }}
                      style={{ marginLeft: "10px" }}
                      onClick={() => loadList()}
                    />
                  )}

                  {!props.createDisabled && (
                    <>
                      <Button
                        icon="pi pi-plus"
                        label="Add"
                        tooltip="Add"
                        tooltipOptions={{ position: "top" }}
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          if (props.AddComponent) {
                            console.log("opa");
                            setEntityInstanceToAddOrEdit({});
                            setShowAddOrEditDialog(true);
                          } else {
                            navigate("create");
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              {saving && (
                <>
                  <div className="w-full mb-0 mt-2">
                    <ProgressBar
                      mode="indeterminate"
                      style={{ height: "6px" }}
                    ></ProgressBar>
                  </div>
                </>
              )}
            </>
          }
        >
          {props.columns.map((column: EntityListColumn, i: number) =>
            typeof column.body === "string" ? (
              <Column
                key={`column_${i}`}
                body={(data: any) => (
                  <span
                    style={props.editDisabled ? {} : { cursor: "pointer" }}
                    onClick={
                      props.editDisabled ? () => {} : () => navigate(data.id)
                    }
                  >
                    {_.get(data, column.body as string)}
                  </span>
                )}
                header={column.title}
                sortField={column.sortField}
                sortable={column.sortable}
                className="x-table-column"
              ></Column>
            ) : (
              <Column
                key={`column_${i}`}
                sortField={column.sortField}
                body={(data: any) => renderColumnBody(column, data)}
                header={column.title}
                sortable={column.sortable}
                className="x-table-column"
              ></Column>
            )
          )}
          <Column
            body={(data: any) =>
              props.columns.map((column: EntityListColumn, i: number) => (
                <>
                  <span
                    key={`column_${i}`}
                    style={{
                      fontWeight: (
                        props.summary?.highlightdIndexes || []
                      ).includes(i)
                        ? "bold"
                        : "normal",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(data.id)}
                  >
                    {typeof column.body === "string"
                      ? _.get(data, column.body)
                      : column.body(data)}
                  </span>
                  <br />
                </>
              ))
            }
            header={props.title}
            sortable={props.summary?.sortable}
            sortField={props.summary?.sortField}
            className="x-table-column-summary"
          ></Column>

          {(!props.hideDetailsButton ||
            !props.deleteDisabled ||
            (props.extraButtons ?? []).filter((b) => b.perItem).length > 0) && (
            <Column
              className="w-1"
              body={(data: any) => (
                <div className="flex flex-column w-full align-items-end md:flex-row md:justify-content-end">
                  {(props.extraButtons ?? [])
                    .filter(
                      (b) =>
                        b.perItem && (!b.showIf || (b.showIf && b.showIf(data)))
                    )
                    .map((btn) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className={btn.className ?? ""}
                      >
                        <Button
                          icon={btn.icon}
                          aria-label={btn.label}
                          rounded
                          title={btn.label}
                          onClick={async () => {
                            btn.onClick(data);
                            await loadList();
                          }}
                        />
                      </div>
                    ))}
                  {!props.hideDetailsButton && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        icon="pi pi-eye"
                        aria-label="Details"
                        rounded
                        onClick={() => navigate(data.id)}
                      ></Button>
                    </div>
                  )}
                  {!props.deleteDisabled && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        icon="pi pi-trash"
                        severity="danger"
                        aria-label="Delete"
                        rounded
                        onClick={() =>
                          confirmDialog({
                            message: `Do you really want to delete this ${
                              props.entityLabel ?? "item"
                            } ?`,
                            header: "Confirmation",
                            icon: "pi pi-exclamation-triangle",
                            accept: async () => deleteInstance(data.id),
                          })
                        }
                      ></Button>
                    </div>
                  )}
                </div>
              )}
            />
          )}
        </DataTable>
        <Paginator
          first={(page - 1) * rowsPerPage}
          rows={rowsPerPage}
          totalRecords={total}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageChange={(e: PaginatorPageChangeEvent) => {
            console.log(e);
            setPage(e.page + 1);
            setRowsPerPage(e.rows);
          }}
        />
      </div>

      {AddComponent && showAddOrEditDialog && entityInstanceToAddOrEdit && (
        <Dialog
          header={`Add${props.entityLabel ? ` ${props.entityLabel}` : ""}`}
          visible={true}
          onHide={() => {
            setShowAddOrEditDialog(false);
            setLoading(false);
          }}
          style={{ minWidth: "60vw" }}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => {
                  setShowAddOrEditDialog(false);
                  setLoading(false);
                }}
                className="p-button-text"
              />
              <Button
                label="Save"
                icon="pi pi-check"
                onClick={() => save()}
                autoFocus
              />
            </div>
          }
        >
          {AddComponent(
            entityInstanceToAddOrEdit,
            setEntityInstanceToAddOrEdit
          )}
        </Dialog>
      )}
    </>
  );
}
