import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Routes, Route } from "react-router-dom";
import AdminApp from "./admin/AdminApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="558463217537-6kfnada5097j0lqrjo7hg561n881sfj3.apps.googleusercontent.com">
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
        <Route path="/admin/*" element={<AdminApp />} />
      </Routes>
    </BrowserRouter>
  </GoogleOAuthProvider>
);
