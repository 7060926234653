/* eslint-disable react/react-in-jsx-scope */
import moment from "moment";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { EntityService } from "../../_shared/services/EntityService";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { formatPhone } from "../../_shared/services/UtilsService";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { MessagesService } from "../../_shared/services/MessagesService";
import { useParams } from "react-router-dom";

const formatMessageTimestamp = (ts: string, justDate = false): string => {
  if (!ts) {
    return "";
  }
  const tsDate: string = moment(ts).format("YYYY-MM-DD");
  const tsTime: string = moment(ts).format("HH:mm");
  const todayAsString: string = moment().format("YYYY-MM-DD");
  const yesterdayAsString: string = moment()
    .add(-1, "day")
    .format("YYYY-MM-DD");
  if (tsDate === todayAsString) {
    if (justDate) {
      return "Today";
    }

    return tsTime;
  } else if (tsDate === yesterdayAsString) {
    return "Yesterday";
  }

  return (
    tsDate.split("-")[2] +
    "/" +
    tsDate.split("-")[1] +
    "/" +
    tsDate.split("-")[0]
  );
};

function ConversationHeader(props: {
  conversation: any;
  onUpdateConversation: (conversation: any) => void;
  onBotEngagedChange: (engage: boolean) => void;
}) {
  const [saving, setSaving] = useState<boolean>(false);
  const [botEngaged, setBotEngaged] = useState<boolean>(
    props.conversation.engage_bot
  );

  const toggle = async (engage: boolean) => {
    setSaving(true);
    const updatedConversation = Object.assign(props.conversation, {
      engage_bot: engage,
    });
    const service = new EntityService("pinterest.bot_control");
    await service
      .updateEngageBot(props.conversation.phone, updatedConversation)
      .then(() => {
        props.onUpdateConversation(updatedConversation);
        setBotEngaged(engage);

        props.onBotEngagedChange(engage);

        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="flex-grow-0 surface-300 p-4 border-round flex justify-content-between">
        <div>
          <b>
            <u>{props.conversation.place?.nome}</u>
          </b>
          <br />
          <b>Phone</b>:{" "}
          <a href={`https://wa.me/${props.conversation.phone}`} target="_new">
            {props.conversation.phone}
          </a>
          <br />
          <b>Address:</b>{" "}
          <a href={props.conversation.place?.mapurl} target="_new">
            {props.conversation.place?.endereco}
          </a>
          {(props.conversation.place?.website ?? "") !== "" ? (
            <>
              <br />
              <b>Website:</b>{" "}
              <a href={props.conversation.place?.website} target="_new">
                {props.conversation.place?.website}
              </a>
            </>
          ) : (
            ""
          )}
          <br />
          <b>Category:</b> {props.conversation.place?.categoria} |{" "}
          <b>Reviews:</b> {props.conversation.place?.reviews ?? 0} |{" "}
          <b>Score:</b> {props.conversation.place?.score ?? "-"}
          <br />
          <br />
          <b>
            <u>From:</u>
          </b>{" "}
          {formatPhone(props.conversation.connected_phone)}
        </div>
        <div className="align-items-center">
          {saving ? (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          ) : (
            <div className="flex align-items-center gap-2">
              <div>
                <b>{botEngaged ? "Desativar" : "Ativar"} AI</b>
              </div>
              <div>
                <InputSwitch
                  checked={botEngaged}
                  onChange={(e) => toggle(!botEngaged)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider layout="horizontal" />
    </>
  );
}

function MessageList(props: { messages: any[]; conversation: any }) {
  const bottomMessagesPanelElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomMessagesPanelElement?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      {props.messages.map((message: any, ind: number) => {
        const date: string = formatMessageTimestamp(
          moment(message.date).format("YYYY-MM-DD"),
          true
        );

        const previousMessage: any = props.messages[ind - 1];
        const showDate =
          !previousMessage ||
          date !==
            formatMessageTimestamp(
              moment(previousMessage?.date).format("YYYY-MM-DD"),
              true
            );
        const showPhone =
          (!previousMessage &&
            props.conversation.connected_phone !== message.connected_phone) ||
          (previousMessage &&
            previousMessage?.connected_phone !== message.connected_phone);
        return (
          <>
            {showPhone && (
              <Divider align="center">
                {formatPhone(message.connected_phone)}
              </Divider>
            )}
            {showDate && (
              <div className="w-full flex justify-content-center mb-2">
                <div className="border-round-sm p-2 bg-primary opacity-50">
                  {date}
                </div>
              </div>
            )}
            <div
              key={`message_${ind}`}
              className={`mb-2 border-round-sm p-2 pb-4 ${
                message.from_me ? "bg-primary" : "surface-300"
              }`}
              style={{
                position: "relative",
                minWidth: "200px",
                maxWidth: "70%",
                alignSelf: message.from_me ? "flex-end" : "flex-start",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: message.content?.replaceAll("\n", "<br/>"),
                }}
              />
              <span
                style={{ position: "absolute", right: "8px", bottom: "6px" }}
              >
                {moment(message.createdon).format("HH:mm")}
              </span>
            </div>
          </>
        );
      })}
      <div ref={bottomMessagesPanelElement}></div>
    </>
  );
}

function NewMessage(props: { to: string; instance: string }) {
  const [newMessageText, setNewMessageText] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const toast: any = useRef(null);
  const sendMessage = async () => {
    try {
      setSending(true);
      const service = new MessagesService();
      await service.send(newMessageText, props.to, props.instance);
      setSending(false);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Message sent",
      });
      setNewMessageText("");
      setSending(false);
    } catch (error: any) {
      setSending(false);
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: error.message,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Divider layout="horizontal" />
      <div className="flex align-items-center">
        <InputTextarea
          autoResize
          value={newMessageText}
          onChange={(e) => setNewMessageText(e.target.value)}
          rows={1}
          className="w-full"
          readOnly={sending}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.ctrlKey) {
              sendMessage();
            }
          }}
        />
        {sending ? (
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <Button
            disabled={sending}
            icon="pi pi-send"
            rounded
            text
            aria-label="Send"
            onClick={sendMessage}
            size="large"
          />
        )}
      </div>
      <div className="w-full text-right pr-2 text-sm mt-2">
        (Ctrl + Enter to send)
      </div>
    </>
  );
}

export default function MessagesByPhone(props: {
  fullScreen: boolean;
  setFullScreen: (flag: boolean) => void;
}) {
  const params = useParams();
  const subscriptionId: string =
    params.subscriptionId ?? "b7087c1f4f89e63af8d46f3b20271153";
  const scenarioid: string | undefined = params.scenarioId;
  const [searchKey, setSearchKey] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(true);
  const [conversations, setConversations] = useState<any[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [conversationTimed, setConversationTimed] = useState<boolean>(false);

  const listConversations = async (retryCount = 0) => {
    try {
      setConversationTimed(true);
      const service = new EntityService("pinterest.bot_control");
      const queryParams = `subscription_id=${subscriptionId}${
        scenarioid ? `,scenario_id=${scenarioid}` : ""
      }${searchKey !== "" ? `,phone*"place"->>'nome'=${searchKey}` : ""}`;
      const _conversations = await service.list(
        1,
        200,
        "last_message_sent",
        -1,
        queryParams
      );
      setConversations(_conversations.items);
      setSearching(false);
    } catch (error) {
      console.error("Error listing conversations:", error);
      setSearching(false);
      setConversationTimed(false);
      if (retryCount < 3) {
        // Maximum retry count
        setTimeout(() => listConversations(retryCount + 1), 30000); // Retry after 30 seconds
      }
    } finally {
      setSearching(false);
      setConversationTimed(false);
    }
  };

  const loadMessages = async (conversation: { phone: any }) => {
    try {
      setConversationTimed(true);
      const service = new EntityService("pinterest.messages");
      const queryParams = `subscriptionid=${subscriptionId}${
        scenarioid ? `,scenario_id=${scenarioid}` : ""
      },phone=${conversation.phone}`;
      const phoneMessages = await service.list(
        1,
        200,
        "moment",
        1,
        queryParams
      );
      console.log("Loading messages for", phoneMessages.items);
      setMessages(phoneMessages.items);
    } catch (error) {
      setConversationTimed(false);
      console.error("Error loading messages:", error);
    } finally {
      setConversationTimed(false);
    }
  };

  const selectConversation = async (conversation: any) => {
    setLoadingMessages(true);
    try {
      await loadMessages(conversation).then(() => {
        setSelectedConversation(conversation);
      });
    } catch (error) {
      console.error("Error selecting conversation:", error);
    } finally {
      setLoadingMessages(false);
    }
  };

  const fetchConversations = async () => {
    try {
      await listConversations();
      if (selectedConversation) {
        await loadMessages(selectedConversation);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    console.log("Fetching conversations...");
    // Initial fetch

    // Set up timer to fetch conversations every 15 seconds if not already fetching
    const conversationsTimer = setInterval(async () => {
      if (!conversationTimed) {
        await fetchConversations();
      }
    }, 3600000); // 1 hour

    // Clear the timer on component unmount
    return () => {
      console.log("Clearing conversations timer...");
      clearInterval(conversationsTimer);
      // Cancel all pending requests
      const service = new EntityService("pinterest.bot_control");
      service.cancelAllRequests();
    };
  }, [selectedConversation]);

  const clipOn = (content: string, length: number): string => {
    if ((content ?? "").length <= length) return content;

    return `${content.substring(0, length)}...`;
  };

  const handleBotEngagedChange = async (engage: boolean) => {
    if (selectedConversation.engage_bot !== engage) {
      setConversations({
        ...conversations,
        [conversations.indexOf(selectedConversation)]: {
          ...selectedConversation,
          engage_bot: engage,
        },
      });
    }
  };

  return (
    <div className={`font-medium text-900 flex-auto flex relative p-4 pt-0`}>
      <i
        style={{ fontSize: "1.2rem" }}
        className={`pi pi-window-${
          props.fullScreen ? "minimize" : "maximize"
        } absolute right-0 mr-1 top-0 mt-1 cursor-pointer`}
        onClick={() => props.setFullScreen(!props.fullScreen)}
      />
      <div className="w-4 flex relative mt-4">
        <div className="w-full flex flex-column absolute top-0 bottom-0">
          <div className="w-full">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                placeholder="Search"
                className="w-full"
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    listConversations();
                  }
                }}
              />
            </span>
          </div>
          <Divider layout="horizontal" />
          {searching ? (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          ) : (
            <div className="w-full overflow-y-auto">
              {conversations?.map((conversation) => (
                <div
                  key={conversation.phone}
                  className={`w-full flex mb-2 p-2 pb-0 ${
                    conversation.phone === selectedConversation?.phone
                      ? "surface-300 border-round"
                      : "cursor-pointer"
                  }`}
                  onClick={() => selectConversation(conversation)}
                >
                  <div></div>
                  <div className="w-full flex flex-column border-bottom-1 border-300 pb-2">
                    <div className="flex justify-content-between">
                      <div>
                        <b>{conversation.place.nome}</b>
                      </div>
                      <div>
                        {formatMessageTimestamp(conversation.last_message_ts)}
                      </div>
                    </div>
                    <div>{formatPhone(conversation.phone)}</div>
                    <div>{clipOn(conversation.last_message, 40)}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Divider layout={"vertical"} className="mt-4" />
      <div className="w-8 flex relative mt-4">
        <div className="w-full flex flex-column absolute top-0 bottom-0">
          {loadingMessages ? (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          ) : (
            <>
              {selectedConversation ? (
                <>
                  <ConversationHeader
                    conversation={selectedConversation}
                    onUpdateConversation={setSelectedConversation}
                    onBotEngagedChange={handleBotEngagedChange}
                  />
                  <div className="w-full text-center">
                    <Button
                      className=" w-full text-center justify-content-center"
                      severity="secondary"
                      onClick={() => loadMessages(selectedConversation)}
                    >
                      Atualizar conversa
                    </Button>
                  </div>
                  <Divider layout="horizontal" />
                  <div className="flex-grow-1 overflow-y-auto flex flex-column">
                    <MessageList
                      messages={messages}
                      conversation={selectedConversation}
                    />
                  </div>
                  {messages.length > 0 && (
                    <div className="flex-grow-0">
                      <NewMessage
                        to={selectedConversation.phone}
                        instance={messages[messages.length - 1].instanceid}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="hidden md:block w-12 bg-no-repeat bg-center"
                  style={{
                    height: "100vh",
                    backgroundSize: "100px",
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/diwo-negative.png')`,
                  }}
                ></div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
