export enum ScenarioStatus {
  Running = "Running",
  Paused = "Paused",
  InstanceDisconnected = "InstanceDisconnected",
}

export type Scenario = {
  customer: string;
  data_source?: string;
  instance_name: string;
  scenario_id: string;
  scenario_name: string;
  status: ScenarioStatus;
  connectedPhone?: string;
  nextExecution?: string;
  subscriptionId?: string;
  qtd_leads?: number;
  sales_rep_name?: string;
  lat_long?: string;
  category?: string;
  geo_filter?: string;
  other_filter?: string;
  prompt?: string;
  webhook?: string;
  test_mode?: boolean;
  notification_email?: string;
  bot_active?: boolean;
  pitch?: string;
  zap_farm_id?: string;
  approach?: string;
  channel?: string;
  chatbotid?: string;
  snovio_list?: string;
  snovio_campaign?: string;
  test_mode_phone?: string;
  test_messages_count?: number;
  istrash?: boolean;
  sleep_time_bot?: number;
  first_message_prompt?: string;
  internal_prompt?: string;
  prompt_temperature?: number;
  prompt_maximum_length?: number;
  prompt_top_p?: number;
  model?: string;
  custom_info?: object | string;
  followup_prompts?: object | string;
  classification_prompts?: object | string;
  prompt_memory_window?: number;
  prompt_max_messages?: number;
  config?: any;
};

export type Config = {
  startingPoint?: string;
  qtdLeads?: string | number;

  sheetname?: string;
  spreadshetId?: string;
  crm?: string;
  usertoken?: string;
  mapping?: string;
};

export type Subscription = {
  id: string;
  name: string;
  status?: string;
  creditsInfo?: {
    remaining: number;
    spent: number;
    total: number;
  };
  scenarios?: Scenario[] | undefined;
  totalScenarios?: number | undefined;
};

export type Place = {
  placeid: string;
  nome: string;
  categoria: string;
  telefone: string;
  iswhatsapp: boolean;
  endereco: string;
  latitude: number;
  longitude: number;
  score: number;
  reviews: number;
  website: string;
  status: string;
  mapurl: string;
  searchphrase: string;
  cep: string;
  bairro: string;
  cidade: string;
  uf: string;
  pais: string;
  createdate: string;
  updatedate: string;
};

export type BillingData = {
  consumptionDate: string;
  captureCredits: string;
  whatsappCheckCredits: string;
  messageSentCredits: string;
  place: Place;
};

export type State = {
  id: string;
  name: string;
};

export type City = {
  id: string;
  name: string;
  state: string;
  lat: number;
  lng: number;
  state_name: string;
  country_name: string;
  country_code: string;
  population: number;
};

export enum DataCaptureProcessStatus {
  NotStarted = "NOT_STARTED",
  CapturingData = "CAPTURING_DATA",
  StoringData = "STORING_DATA",
  DataStored = "DATA_STORED",
  Geocoding = "GEOCODING",
  CheckingWhatsapp = "CHECKING_WHATSAPP",
  Finished = "FINISHED",
  Error = "ERROR",
  Geocoded = "GEOCODED",
  WhatsappChecked = "WHATSAPP_CHECKED",
}

export type DataCaptureProcess = {
  language: string;
  id: string;
  category: string;
  state: string;
  city?: string;
  allocated_memory: 2048 | 4096 | 8192 | 16384 | 32768;
  status: DataCaptureProcessStatus;
  status_detail?: string;
  created_on: string;
  last_updated_on: string;
  finished_on?: string;
  record_count?: number;
  runid: string;
  datasetid: string;
  cities: City[];
};
