/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { SplitButton } from "primereact/splitbutton";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionsService } from "../../_shared/services/SubscriptionsService";
import {
  formatCurrency,
  formatPhone,
} from "../../_shared/services/UtilsService";
import {
  Scenario,
  ScenarioStatus,
  Subscription,
} from "../../_shared/types/types";
import ScenarioUpdateModal from "../components/ScenarioUpdateModal";
import { getAdminUserFromLocalStorage } from "../../utils/userLocalStorage";
import { Paginator } from "primereact/paginator";
interface ScenariosScreenProps {
  subscription: Subscription;
  onSubscriptionUpdate: (_subscription: Subscription) => void;
}

export default function ScenariosScreen(props: ScenariosScreenProps) {
  const [currentScenarioId, setCurrentScenarioId] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedScenarioType, setSelectedScenarioType] =
    useState<string>("GoogleMaps"); // Estado para o SelectButton

  const [currentSubscription, setCurrentSubscription] = useState<Subscription>(
    props.subscription
  );

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);

  const onPageChange = (event: any) => {
    setCurrentSubscription({
      ...currentSubscription,
      scenarios: [],
    });
    setProcessing(true);
    loadSubscription(currentSubscription.id, event.page + 1, event.rows);
    setFirst(event.first);
    setRows(event.rows);
  };

  const toast: any = useRef(null);

  const refresh = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentPage = urlParams.get("currentPage") || 1;
    const perPage = urlParams.get("perPage") || 10;

    const _subscription =
      await new SubscriptionsService().getSubscriptionByCode(
        props.subscription.id,
        Number(currentPage),
        Number(perPage)
      );

    await props.onSubscriptionUpdate(_subscription);
    setCurrentSubscription(_subscription);
  };

  console.log("props", props.subscription);

  const loadSubscription = async (
    subscriptionid: string,
    currentPage: number,
    perPage: number
  ) => {
    const _subscription =
      await new SubscriptionsService().getSubscriptionByCode(
        subscriptionid,
        currentPage,
        perPage
      );

    window.history.replaceState(
      {},
      "",
      `?currentPage=${currentPage}&perPage=${perPage}`
    );
    setFirst((currentPage - 1) * perPage);
    setRows(perPage);
    setCurrentSubscription(_subscription);
    setProcessing(false);
  };

  useEffect(() => {
    setProcessing(true);

    const urlParams = new URLSearchParams(window.location.search);
    const currentPage = urlParams.get("currentPage") || 1;
    const perPage = urlParams.get("perPage") || 10;
    // update url with current page and per page
    window.history.replaceState(
      {},
      "",
      `?currentPage=${currentPage}&perPage=${perPage}`
    );

    loadSubscription(
      props.subscription.id,
      Number(currentPage),
      Number(perPage)
    );
  }, []);

  const removeSetTimeout = () => {
    let id = window.setTimeout(function () {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }

    // clear all intervals
    id = window.setInterval(function () {}, 0);
    while (id--) {
      window.clearInterval(id);
    }
  };

  const pause = async (scenarioId: string) => {
    confirmDialog({
      message: "Deseja realmente desativar o cenário?",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().pauseScenario(
            props.subscription.id,
            scenarioId
          );
          await refresh();
          setProcessing(false);
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Cenário desativado com sucesso",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const unpause = async (scenarioId: string) => {
    confirmDialog({
      message: "Deseja realmente re-ativar o cenário?",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().unpauseScenario(
            props.subscription.id,
            scenarioId
          );
          await refresh();
          setProcessing(false);
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Cenário re-ativado com sucesso",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const run = async (scenarioId: string) => {
    confirmDialog({
      message: "Deseja realmente executar o cenário agora?",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().executeScenario(
            props.subscription.id,
            scenarioId
          );
          await refresh();
          setProcessing(false);
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Execução do cenário iniciada com sucesso",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const disconnectScenario = async (scenarioId: string, phone: string) => {
    confirmDialog({
      message: `Deseja realmente desconnectar o número ${formatPhone(phone)} ?`,
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().disconnectScenarioInstance(
            props.subscription.id,
            scenarioId
          );
          await refresh();
          setProcessing(false);
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Número desconectado com sucesso",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const [showQRCode, setShowQRCode] = useState<boolean>(false);
  const [qrCodeInfo, setQrCodeInfo] = useState<any>(undefined);

  const reconnect = async (scenarioId: string) => {
    setShowQRCode(true);
    setProcessing(true);
    setCurrentScenarioId(scenarioId);

    console.log("qrCodeData");

    return await new SubscriptionsService()
      .getScenarioQRCode(props.subscription.id, scenarioId)
      .then((qrCodeData) => {
        console.log("then", qrCodeData);
        setQrCodeInfo(qrCodeData);
        setShowQRCode(true);
        if (qrCodeData && qrCodeData.qrcode?.value) {
          console.log("achou", qrCodeData);
          setTimeout(() => check(scenarioId), 1000);
        } else {
          console.log("nada ainda");
          if (showQRCode) {
            setTimeout(() => reconnect(scenarioId), 5000);
          }
        }
      })
      .catch((error) => {
        console.log("errorrr", error);
        setQrCodeInfo({ error: error });
      });
  };

  let intervalId: any;
  const check = async (scenarioId: string) => {
    console.log("checking", scenarioId);

    setCurrentScenarioId(scenarioId);
    const checkConnection = async () => {
      const checkData =
        await new SubscriptionsService().checkScenarioInstanceConnection(
          props.subscription.id,
          scenarioId
        );

      if (checkData.connected) {
        console.log("conectou");
        clearInterval(intervalId);
        removeSetTimeout();

        await refresh();
        setProcessing(false);
        setShowQRCode(false);
        setCurrentScenarioId("");
        setQrCodeInfo(false);

        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Whatsapp re-conectado com sucesso",
        });
      } else {
        console.log("ainda nao conectou");
      }
      console.log("checkData");
    };

    intervalId = setInterval(checkConnection, 2000);

    setTimeout(async () => {
      clearInterval(intervalId);

      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: "Tempo excedido de conexão. Por favor tente novamente.",
      });

      setQrCodeInfo(false);
      setProcessing(false);
      setShowQRCode(false);

      await refresh();
    }, 300000); // 5 minutes
    // Initial check
    await checkConnection();
  };

  const save = async () => {
    if (selectedScenario) {
      try {
        const {
          qtd_leads,
          category,
          lat_long,
          geo_filter,
          other_filter,
          sales_rep_name,
          prompt,
          bot_active,
          webhook,
          scenario_name,
          test_mode,
          test_mode_phone,
          prompt_memory_window,
          prompt_max_messages,
          config,
        } = selectedScenario;
        setProcessing(true);
        await new SubscriptionsService().saveScenario(
          props.subscription.id,
          selectedScenario?.scenario_id,
          {
            qtd_leads,
            category,
            lat_long,
            geo_filter,
            other_filter,
            sales_rep_name,
            bot_active,
            prompt,
            webhook,
            scenario_name,
            test_mode,
            test_mode_phone,
            prompt_memory_window,
            prompt_max_messages,
            config,
          }
        );
        await refresh();
        setProcessing(false);
        setSelectedScenario(undefined);
        setShowParameters(false);
        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Cenário atualizado com sucesso",
        });
      } catch (error: any) {
        setProcessing(false);
        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      }
    }
  };
  const cleanTestMessage = async () => {
    if (selectedScenario) {
      try {
        setProcessing(true);
        await new SubscriptionsService().clearScenarioTestMessages(
          props.subscription.id,
          selectedScenario?.scenario_id
        );
        await refresh();
        setSelectedScenario(
          Object.assign(selectedScenario, { test_messages_count: 0 })
        );
        setProcessing(false);
        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Mensagens de teste apagadas com sucesso",
        });
      } catch (error: any) {
        setProcessing(false);
        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      }
    }
  };

  const [showParameters, setShowParameters] = useState<boolean>(true);
  const [selectedScenario, setSelectedScenario] = useState<
    Scenario | undefined
  >(undefined);

  const navigate = useNavigate();

  const jsonList =
    '{\n  "sheetData": {\n    "F": "",\n    "G": "",\n    "H": "",\n    "I": "",\n    "J": "",\n    "K": "",\n    "L": "",\n    "M": "",\n    "N": "",\n    "O": ""\n  },\n  "sheetName": "",\n  "spreadsheetId": ""\n}';

  const [showCreateScenario, setShowCreateScenario] = useState<boolean>(false);
  const [newScenario, setNewScenario] = useState<Scenario>({
    scenario_name: "",
    customer: "",
    instance_name: "",
    scenario_id: "",
    status: ScenarioStatus.InstanceDisconnected,
    config: selectedScenarioType === "List" ? jsonList : {},
  });

  const createScenario = async () => {
    confirmDialog({
      message: `Deseja realmente criar o cenário ${newScenario.scenario_name} ?`,
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().createScenario(
            props.subscription.id,
            newScenario.scenario_name,
            selectedScenarioType
          );
          setProcessing(false);
          setNewScenario({
            scenario_name: "",
            customer: "",
            instance_name: "",
            scenario_id: "",
            status: ScenarioStatus.InstanceDisconnected,
          });
          setShowCreateScenario(false);
          await refresh();
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail:
              "Criação do cenário iniciada com sucesso. Por favor atualize a página dento de alguns instantes caso o novo cenário não apareça na lista.",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const updateScenario = async (scenario: Scenario) => {
    navigate(
      `/admin/${props.subscription.id}/scenarios/${scenario.scenario_id}/edit`
    );
  };

  const deleteScenario = async (scenario: Scenario) => {
    confirmDialog({
      message: `Deseja realmente deletar o cenário ${scenario.scenario_name} ?`,
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().deleteScenario(
            props.subscription.id,
            scenario.scenario_id
          );
          setProcessing(false);
          await refresh();
          toast?.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail:
              "Deleção do cenário iniciada com sucesso. Por favor atualize a página dento de alguns instantes caso o cenário não desapareça da lista.",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const getScenarioActions = (scenario: Scenario): ReactNode => {
    const items = [];

    items.push({
      label: "Conversas",
      icon: "pi pi-whatsapp",
      command: () => {
        navigate(
          `/${props.subscription.id}/scenarios/${scenario.scenario_id}/zap`
        );
      },
    });

    if (
      props.subscription.status === "active" &&
      scenario.status !== ScenarioStatus.InstanceDisconnected
    ) {
      items.push({
        label: "Executar Agora",
        icon: "pi pi-bolt",
        command: () => run(scenario.scenario_id),
      });
    }

    if (
      props.subscription.status === "active" &&
      scenario.status === ScenarioStatus.InstanceDisconnected
    ) {
      items.push({
        label: "Conectar",
        icon: "pi pi-qrcode",
        command: () => {
          reconnect(scenario.scenario_id);
        },
      });
    }

    items.push({
      label: "Configurar",
      icon: "pi pi-cog",
      command: () => {
        setSelectedScenario(scenario);
        setShowParameters(true);
      },
    });

    if (
      props.subscription.status === "active" &&
      scenario.status === ScenarioStatus.Paused
    ) {
      items.push({
        label: "Ativar",
        icon: "pi pi-play",
        command: () => unpause(scenario.scenario_id),
      });
    }

    if (
      props.subscription.status === "active" &&
      scenario.status === ScenarioStatus.Running
    ) {
      items.push({
        label: "Desativar",
        icon: "pi pi-pause",
        command: () => pause(scenario.scenario_id),
      });
    }

    if (
      props.subscription.status === "active" &&
      scenario.status !== ScenarioStatus.InstanceDisconnected
    ) {
      items.push({
        label: "Desconectar",
        icon: "pi pi-power-off",
        command: () => {
          disconnectScenario(
            scenario.scenario_id,
            scenario.connectedPhone ?? ""
          );
        },
      });
    }

    if (getAdminUserFromLocalStorage()?.email) {
      items.push({
        label: "Editar",
        icon: "pi pi-pencil",
        command: () => updateScenario(scenario),
      });
    }

    items.push({
      label: "Deletar",
      icon: "pi pi-trash",
      command: () => deleteScenario(scenario),
    });

    return (
      <span className="p-buttonset">
        {items.splice(0, 2).map((item, index) => (
          <Button
            key={index}
            label={item.label}
            icon={item.icon}
            onClick={item.command}
            size="small"
            className="p-button-text p-button-plain"
          />
        ))}
        {items.length >= 2 ? (
          <SplitButton
            disabled={processing}
            label={items[0].label}
            icon={items[0].icon}
            dropdownIcon="pi pi-ellipsis-v"
            onClick={items[0].command}
            model={items.splice(1, items.length)}
            size="small"
            className="p-button-text p-button-plain"
          />
        ) : (
          <SplitButton
            disabled={processing}
            label={items[0].label}
            icon={items[0].icon}
            onClick={items[0].command}
            model={items.splice(0, items.length)}
            size="small"
            className="p-button-text p-button-plain"
          />
        )}
      </span>
    );
  };

  const onCloseQrCode = async () => {
    removeSetTimeout();

    if (
      currentScenarioId !== "" &&
      currentScenarioId.length &&
      showQRCode &&
      qrCodeInfo &&
      qrCodeInfo.qrcode?.value
    ) {
      await new SubscriptionsService()
        .disconnectScenarioInstance(props.subscription.id, currentScenarioId)
        .then(() => {
          setCurrentScenarioId("");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    setShowQRCode(false);
    setProcessing(false);
    setQrCodeInfo(false);

    await refresh();
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="p-4 w-full">
        <div className="flex justify-content-between align-items-center mb-5 w-full">
          <div className="text-xl text-900 font-medium">
            Créditos e Cenários
            {currentSubscription.status === "inactive" && (
              <Tag
                severity="warning"
                value="Subscrição  Inativa"
                className="ml-4"
              ></Tag>
            )}
          </div>
          {currentSubscription.creditsInfo && (
            <>
              <div className="surface-card shadow-2 border-round text-lg text-900 font-small p-2 flex align-items-center">
                <b>Créditos:</b>&nbsp;
                {formatCurrency(currentSubscription.creditsInfo.total)}
                &nbsp;|&nbsp;
                <b>Consumo:</b>&nbsp;
                {formatCurrency(currentSubscription.creditsInfo.spent)}
                &nbsp;|&nbsp;
                <b>Saldo:</b>&nbsp;
                {formatCurrency(currentSubscription.creditsInfo.remaining)}
                <Button
                  label="Ver Detalhes"
                  link
                  onClick={() =>
                    navigate(`/${currentSubscription.id}/statement`)
                  }
                />
              </div>
            </>
          )}
          <div>
            <Button
              label="Create"
              icon="pi pi-plus"
              onClick={() => setShowCreateScenario(true)}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
        {processing && (
          <>
            <div className="w-full mb-2">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </>
        )}
        <DataTable
          value={currentSubscription.scenarios || []}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage={
            processing ? "Carregando..." : "Nenhum cenário encontrado"
          }
        >
          <Column field="scenario_name" header="Nome"></Column>
          <Column
            header="Status"
            body={(scenario: Scenario) => (
              <>
                {scenario.status === ScenarioStatus.Running ? (
                  <Tag
                    className="text-xs"
                    severity="success"
                    value="Ativado"
                  ></Tag>
                ) : scenario.status === ScenarioStatus.Paused ? (
                  <Tag
                    className="text-xs"
                    severity="warning"
                    value="Desativado"
                  ></Tag>
                ) : scenario.status === ScenarioStatus.InstanceDisconnected ? (
                  <Tag
                    className="text-xs"
                    severity="danger"
                    value="Whatsapp Desconectado"
                  ></Tag>
                ) : (
                  <></>
                )}
                {scenario.test_mode && (
                  <>
                    <br />
                    <Tag
                      className="text-xs mt-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Modo Teste"
                    ></Tag>
                  </>
                )}
              </>
            )}
          ></Column>
          <Column
            header="Número Conectado"
            body={(scenario: Scenario) =>
              scenario.status === ScenarioStatus.InstanceDisconnected
                ? "-"
                : formatPhone(scenario.connectedPhone ?? "")
            }
          ></Column>
          <Column
            header="Próxima Execução"
            body={(scenario: Scenario) =>
              scenario.status === ScenarioStatus.Running
                ? !scenario.nextExecution
                  ? "-"
                  : moment(scenario.nextExecution).format(
                      "DD/MM/YYYY HH:mm:ss "
                    )
                : "-"
            }
          ></Column>
          <Column
            className="text-right"
            body={(scenario: Scenario) => getScenarioActions(scenario)}
          ></Column>
        </DataTable>
        <div className="card">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={currentSubscription.totalScenarios}
            rowsPerPageOptions={[10]}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <Dialog
        header="QR Code"
        visible={showQRCode}
        onHide={() => onCloseQrCode()}
      >
        {qrCodeInfo && qrCodeInfo.qrcode?.value ? (
          <>
            <img src={qrCodeInfo.qrcode.value} alt="QR Code" />
          </>
        ) : (
          <div
            className="w-full"
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            {qrCodeInfo && qrCodeInfo.error && (
              <p>
                Não há instâncias disponivel <br /> tente novamente ou entre em
                contato com suporte{" "}
              </p>
            )}
            {!qrCodeInfo && (
              <>
                <p>Aguardando Instancia</p>
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                  title="Aguardando Instancia"
                />
              </>
            )}
          </div>
        )}
      </Dialog>
      {showParameters && selectedScenario && (
        <>
          <ScenarioUpdateModal
            selectedScenario={selectedScenario}
            onScenarioUpdate={(scenario: Scenario) =>
              setSelectedScenario(scenario)
            }
            onSave={() => save()}
            onHide={() => {
              setShowParameters(false);
              setProcessing(false);
            }}
            processing={processing}
            onCleanTestMessage={() => cleanTestMessage()}
          />
        </>
      )}
      {showCreateScenario && (
        <>
          <Dialog
            header={"Criar Cenário"}
            visible={true}
            onHide={() => {
              setShowCreateScenario(false);
              setProcessing(false);
            }}
            footer={
              <div>
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  onClick={() => {
                    setShowCreateScenario(false);
                    setProcessing(false);
                  }}
                  className="p-button-text"
                />
                <Button
                  label="Salvar"
                  icon="pi pi-check"
                  onClick={() => createScenario()}
                  autoFocus
                />
              </div>
            }
          >
            <div
              className="w-full"
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                minWidth: "400px",
              }}
            >
              <div className="font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Nome:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    value={newScenario.scenario_name ?? ""}
                    onChange={(e) =>
                      setNewScenario(
                        Object.assign(
                          {},
                          { ...newScenario, scenario_name: e.target.value }
                        )
                      )
                    }
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                <label htmlFor="scenarioType" className="font-bold">
                  Tipo:
                </label>
              </div>
              <Dropdown
                id="scenarioType"
                value={selectedScenarioType}
                options={[
                  { label: "GoogleMaps", value: "GoogleMaps" },
                  { label: "List", value: "List" },
                  { label: "CRM", value: "CRM" },
                ]}
                onChange={(e) => setSelectedScenarioType(e.value)}
              />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
