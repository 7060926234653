import React, { useState, useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import Subscriptions from './screens/Subscriptions';
import Loading from '../_shared/components/Loading';
import { Routes, Route } from 'react-router-dom';
import GoogleLogin from './screens/GoogleLogin';
import { GoogleUser } from './types/types';
import Header from '../_shared/components/Header';
import WhatsappInstances from './screens/WhatsappInstances';
import DataCaptureProcesses from './screens/DataCaptureProcesses';
import BotControl from './screens/BotControl';
import MessagesByPhone from './screens/MessagesByPhone';
import EditScenario from './screens/EditScenario';

export default function AdminApp() {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const [user, setUser] = useState<GoogleUser | undefined>(undefined);

  const onUserUpdate = async (user: GoogleUser) => {
    localStorage.setItem('adminUser', JSON.stringify(user));
    setUser(user);
    setLoggedIn(true);
  };

  const onLogout = () => {
    localStorage.clear();
    setUser(undefined);
    setLoggedIn(false);
    googleLogout();
  };

  useEffect(() => {
    const _userData: string | null = localStorage.getItem('adminUser');
    if (_userData) {
      setUser(JSON.parse(_userData));
      setLoggedIn(true);
    }

    setLoading(false);
  }, []);

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  if (loading) {
    return <Loading />;
  } else if (loggedIn) {
    return (
      <>
        <div className='min-h-screen flex flex-column'>
          {!fullScreen && (
            <Header
              logo={'/images/diwo-negative.png'}
              user={user}
              onLogout={onLogout}
              leftMenu={[
                {
                  label: 'Subscriptions',
                  path: '/admin/subscriptions',
                  breadcrumb: ['Admin', 'Subscriptions'],
                  icon: 'pi-users',
                },
                {
                  label: 'Tech Inventory',
                  icon: 'pi-wrench',
                  items: [
                    {
                      label: 'Whatsapp Instances',
                      path: '/admin/instances',
                      icon: 'pi-whatsapp',
                      breadcrumb: [
                        'Admin',
                        'Tech Inventory',
                        'Whatsdapp Instances',
                      ],
                    },
                    {
                      label: 'Data Captures Process',
                      icon: 'pi-database',
                      path: '/admin/processes',
                      breadcrumb: [
                        'Admin',
                        'Tech Inventory',
                        'Data Capture Processes',
                      ],
                    },
                    {
                      label: 'Bot Control',
                      icon: 'pi-android',
                      path: '/admin/botcontrol',
                      breadcrumb: ['Admin', 'Tech Inventory', 'Bot Control'],
                    },
                    {
                      label: 'Whatsapp Web',
                      icon: 'pi-whatsapp',
                      path: '/admin/messages',
                      breadcrumb: ['Admin', 'Tech Inventory', 'Whatsapp Web'],
                    },
                  ],
                },
              ]}
              rightMenu={true}
            />
          )}
          <div
            className={`p-5 flex flex-column flex-auto ${
              fullScreen ? 'bg-primary-500' : ''
            }`}
            style={fullScreen ? {} : { marginTop: '-8rem' }}
          >
            <div className='surface-card shadow-2 border-round flex flex-auto'>
              <Routes>
                <Route path='/' element={<Subscriptions />} />
                <Route path='/subscriptions' element={<Subscriptions />} />
                <Route path='/instances' element={<WhatsappInstances />} />
                <Route path='/processes' element={<DataCaptureProcesses />} />
                <Route path='/botcontrol' element={<BotControl />} />
                <Route
                  path='/messages'
                  element={
                    <MessagesByPhone
                      fullScreen={fullScreen}
                      setFullScreen={setFullScreen}
                    />
                  }
                />
                <Route
                  path=':id/scenarios/:scenarioId/edit'
                  element={<EditScenario />}
                ></Route>
              </Routes>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <GoogleLogin
        onLoginsSuccess={onUserUpdate}
        onLoginFail={(error: string) => window.alert(error)}
      />
    );
  }
}
