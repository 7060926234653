export type GoogleUser = {
  picture?: string;
  name?: string;
  email?: string;
};

export enum InstanceStatus {
  Connecting = "connecting",
  Connected = "connected",
  Disconnected = "disconnected",
}

export enum InstanceType {
  Live = "live",
  Trial = "trial",
  Internal = "internal",
}

export enum InstanceProvider {
  Zapi = "ZAPI",
  Maplead = "MAPLEAD",
}

export type Instance = {
  instance: string;
  token?: string;
  status: InstanceStatus;
  name: string;
  customer: string;
  type: InstanceType;
  provider: InstanceProvider;
  subscribed: boolean;
  deviceInfo?: any
};