import moment from "moment";
import EntityList from "../../_shared/components/EntityList/EntityList";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { DataCaptureProcessesService } from "../../_shared/services/DataCaptureProcessesService";
import { Tag } from "primereact/tag";
import { InputSwitch } from "primereact/inputswitch";
import { AddDataCaptureProcess } from "../components/AddDataCaptureProcess";
import {
  DataCaptureProcess,
} from "../../_shared/types/types";
import { EntityService } from "../../_shared/services/EntityService";
import { ProgressSpinner } from "primereact/progressspinner";

export default function BotControl() {

  const [update, setUpdated] = useState<Date>()
  const [saving, setSaving] = useState<string>("");

  const toggle = async (item: any, engage: boolean) => {
    setSaving(item.phone);
    item.engage_bot = engage
    const service = new EntityService("pinterest.bot_control");
    await service.update(item.phone, item)
    setUpdated(new Date())
    setSaving("");
  };

  return (
    <>
      <EntityList
        title="Bot Control"
        entity="pinterest.bot_control"
        entityLabel="Bot Control"
        defaultSortField="last_message_sent"
        defaultSortOrder={-1}
        createDisabled={true}
        editDisabled={true}
        hideDetailsButton={true}
        columns={[
          {
            title: "Phone",
            body: (item: any) => (
              <>
                <a href={`https://wa.me/${item.phone}`} target="_new">{item.phone}</a>
              </>
            ),
            sortable: true,
            sortField: `phone`,
          },
          {
            title: "Engage Bot",
            sortable: true,
            sortField: `engage_bot`,
            body: (item: any) => 
              saving === item.phone ? (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          ) : (<InputSwitch
              disabled={ saving !== ""}
              checked={item.engage_bot}
              onChange={(e) =>
                toggle(item, !item.engage_bot)
              }
            />
            ),
          },
          {
            title: "Place",
            body: (item: any) => (
              <>
                <a href={item.place?.mapurl} target="_new">{item.place?.nome}</a><br/>
                {item.place?.endereco}<br/>
                {item.place?.cidade} - {item.place?.uf}<br/>
                {item.place?.cep}
                { (item.place?.website ?? "" !== "") ? <><br/><a href={item.place?.website} target="_new">{item.place?.website}</a></>: ""}
                <br/><b>Category:</b> {item.place?.categoria} | <b>Reviews:</b> {item.place?.reviews ?? 0} | <b>Score:</b> {item.place?.score ?? "-"} 

              </>
            ),
            sortable: true,
            sortField: `"place"->>'nome'`,
          },
          {
            title: "Last Message On",
            sortable: true,
            sortField: "last_message_sent",
            body: "last_message_sent"
          }
        ]}
        search={["phone", "connected_phone", `"place"->>'nome'`]}
        deleteDisabled={true}
      />
    </>
  );
}
