import { Instance } from "../../admin/types/types";
import { BaseService } from "./BaseService";

export class InstancesService extends BaseService {
  constructor() {
    super();
    this.path = "/instances/";
  }

  getInstance = async (instanceId: string) => {
    return await this.get(`${instanceId}`);
  };

  updateInstace = async (instanceId: string, data: Instance) => {
    return await this.put(`${instanceId}`, data);
  };
}
