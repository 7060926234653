/* eslint-disable prefer-const */
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { useRef } from "react";
import { Subscription } from "../types/types";
import { GoogleUser } from "../../admin/types/types";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { forEach } from "lodash";

type MenuItem = {
  label: string;
  icon?: string;
  path?: string;
  breadcrumb?: string[];
  items?: MenuItem[];
};

type HeaderProps = {
  logo: string;
  leftMenu?: MenuItem[];
  rightMenu?: any | undefined;
  profile?: any | undefined;
  breadcrumb?: any | undefined;
  user: GoogleUser | Subscription | undefined;
  onLogout?: () => void;
  onBack?: () => void | undefined;
};

export default function Header(props: HeaderProps) {
  const btnRef10 = useRef(null);
  const btnRef11 = useRef(null);
  const menu = useRef(null);

  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Logout",
          icon: "pi pi-logout",
          command: props.onLogout,
        },
      ],
    },
  ];

  let normalizedMenuItemsWithPath: MenuItem[] = [];
  (props.leftMenu || []).forEach((menuItem: MenuItem) => {
    if (menuItem.path && menuItem.breadcrumb) {
      normalizedMenuItemsWithPath.push(menuItem);
    }
    (menuItem.items || []).forEach((subItem: MenuItem) => {
      if (subItem.path && subItem.breadcrumb) {
        normalizedMenuItemsWithPath.push(subItem);
      }
    });
  });

  const breadcrumb: string[] | undefined = (
    normalizedMenuItemsWithPath || []
  ).find(
    (menuItem: MenuItem) => menuItem.path === window.location.pathname
  )?.breadcrumb;

  const navigate = useNavigate();

  return (
    <div
      className="bg-primary-500"
      style={
        breadcrumb || props.onBack ? { height: "250px" } : { height: "180px" }
      }
    >
      <div
        className="py-3 px-5 flex align-items-center justify-content-between relative lg:static"
        style={{ minHeight: "80px" }}
      >
        <img
          src={props.logo}
          alt="Image"
          height="40"
          className="mr-0 lg:mr-6"
        />
        {!props.leftMenu && (
          <div
            className="font-medium text-indigo-100"
            style={{ fontSize: "32px", whiteSpace: "nowrap" }}
          >
            {props.user?.name}
          </div>
        )}
        <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-primary-500 left-0 top-100 z-1">
          {(props.leftMenu ?? []).length > 0 && (
            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
              {props.leftMenu?.map((menuItem: MenuItem, i) => (
                <>
                  {(menuItem.items || []).length === 0 ? (
                    <li key={`menu_item_${i}`}>
                      <a
                        onClick={() => navigate(menuItem?.path ?? "")}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                      >
                        {menuItem.icon && (
                          <i className={`pi ${menuItem.icon} mr-2`}></i>
                        )}
                        <span>{menuItem.label}</span>
                        <Ripple />
                      </a>
                    </li>
                  ) : (
                    <li key={`menu_item_${i}`}>
                      <StyleClass
                        nodeRef={btnRef11}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="scalein"
                        leaveToClassName="hidden"
                        leaveActiveClassName="fadeout"
                        hideOnOutsideClick
                      >
                        <a
                          ref={btnRef11}
                          className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        >
                          {menuItem.icon && (
                            <i className={`pi ${menuItem.icon} mr-2`}></i>
                          )}
                          <span>{menuItem.label}</span>
                          <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        {menuItem.items?.map((subItem: MenuItem, s) => (
                          <li key={`menu_item_${i}_${s}`}>
                            <a
                              onClick={() => navigate(subItem?.path ?? "")}
                              className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            >
                              {subItem.icon && (
                                <i className={`pi ${subItem.icon} mr-2`}></i>
                              )}
                              <span>{subItem.label}</span>
                              <Ripple />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                </>
              ))}
            </ul>
          )}
          {props.rightMenu && (
            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
              {false && (
                <>
                  <li>
                    <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                      <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                      <span className="block lg:hidden font-medium">Inbox</span>
                      <Ripple />
                    </a>
                  </li>
                  <li>
                    <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                      <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                      <span className="block lg:hidden font-medium">
                        Notifications
                      </span>
                      <Ripple />
                    </a>
                  </li>
                </>
              )}
              <li className="border-top-1 border-indigo-400 lg:border-top-none">
                <Menu model={items} popup ref={menu} />
                <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                  <img
                    src={(props.user as GoogleUser).picture}
                    className="mr-3 lg:mr-0"
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "16px",
                    }}
                  />
                  <div className="block lg:hidden">
                    <div className="text-indigo-50 font-medium">
                      {props.user?.name}
                    </div>
                    <span className="text-indigo-100 font-medium text-sm">
                      {(props.user as GoogleUser).email}
                    </span>
                  </div>
                  <Ripple />
                </a>
              </li>
              <li>
                <i
                  className="pi pi-sign-out"
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={props.onLogout}
                />
              </li>
            </ul>
          )}
        </div>
      </div>
      {breadcrumb && (
        <ul className="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-indigo-600">
          <li className="pr-3">
            <a className="cursor-pointer">
              <i className="pi pi-home text-indigo-200"></i>
            </a>
          </li>
          {breadcrumb.map((item: any, i) => (
            <>
              <li className="px-2" key={`bci_${i}`}>
                <i className="pi pi-angle-right text-indigo-200 mr-2"></i>
                <a className="cursor-pointer text-indigo-200 white-space-nowrap">
                  {item}
                </a>
              </li>
            </>
          ))}
        </ul>
      )}
      {!breadcrumb && props.onBack && (
        <ul className="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-indigo-600">
          <li className="pr-3" onClick={props.onBack}>
            <i className="pi pi-angle-left text-indigo-200 mr-2"></i>
            <a className="cursor-pointer text-indigo-200">Voltar</a>
          </li>
        </ul>
      )}
    </div>
  );
}
