import { DataCaptureProcess, Scenario, Subscription } from "../types/types";
import { BaseService } from "./BaseService";

export class DataCaptureProcessesService extends BaseService {
    
    constructor(){
        super()
    }

    start = async () : Promise<void> => {
        await this.getToJobs('trigger-data-capture')
        return
    }

    retry = async (dataCaptureProcess: DataCaptureProcess) : Promise<void> => {
        await this.postToMake("https://hook.us1.make.com/jzarkfbakweo6nqhj56sdyt55s0kudfc", dataCaptureProcess)
        return
    }
}