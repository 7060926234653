import { InputSwitch } from "primereact/inputswitch";
import EntityList from "../../_shared/components/EntityList/EntityList";
import { Instance, InstanceProvider } from "../types/types";

export default function WhatsappInstances() {
  return (
    <EntityList
      title="Whatsapp Instances"
      entity="zapi_instances"
      defaultSortField="name"
      defaultSortOrder={1}
      createDisabled={true}
      hideDetailsButton={true}
      columns={[
        {
          title: "Instance ID",
          sortable: true,
          sortField: "instance",
          body: "instance",
        },
        {
          title: "Name",
          body: "name",
          sortable: true,
          sortField: `name`,
        },
        {
          title: "Customer",
          body: "customer",
          sortable: true,
          sortField: `customer`,
        },
        {
          title: "Status",
          body: "status",
          sortable: true,
          sortField: `status`,
        },
        {
          title: "Type",
          body: "type",
          sortable: true,
          sortField: `type`,
        },
        {
          title: "Provider",
          body: "provider",
          sortable: true,
          sortField: `provider`,
        },
        {
          title: "Subscribed",
          body: () => {
            // render a switch component in entity list
            return null;
          },
          sortField: `subscribed`,
        },
        {
          title: "Device",
          body: (item: Instance) =>
            item.deviceInfo
              ? item.provider === InstanceProvider.Zapi
                ? item.deviceInfo?.phone
                : item.deviceInfo?.user?.id?.split(":")[0] ?? ""
              : "-",
        },
      ]}
      search={[
        "instance",
        "name",
        "customer",
        "status",
        "type",
        "provider",
        "subscribed",
      ]}
    />
  );
}
