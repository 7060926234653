import { Button } from "primereact/button";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { GoogleUser } from "../types/types";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

interface GoogleLoginProps {
  onLoginsSuccess: (_user: GoogleUser) => void;
  onLoginFail: (error: string) => void;
}

export default function GoogleLogin(props: GoogleLoginProps) {
  const [processing, setProcessing] = useState<boolean>(false);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (
      codeResponse: Omit<
        TokenResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      if (codeResponse) {
        try {
          const res = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: "application/json",
              },
            }
          );
          const user: GoogleUser = res.data as GoogleUser;

          if (
            [
              "evarella@gmail.com",
              "mitch@maplead.com.br",
              "apps@maplead.com.br",
              "reginaw85@gmail.com",
              "gabriemslll@gmail.com",
              "huxley@punkmetrics.com",
              "augustosandim@gmail.com",
              "eduranzzani@gmail.com",
              "mariana@diwohub.com",
              "ric@diwohub.com",
              "guilherme.vb.roberto@gmail.com",
            ].includes(user.email ?? "") ||
            (user.email ?? "").endsWith("@maplead.com.br") ||
            (user.email ?? "").endsWith("@diwohub.com") ||
            (user.email ?? "").endsWith("@maplead.ai")
          ) {
            props.onLoginsSuccess(user);
          } else {
            props.onLoginFail(
              `E-mail ${user.email ?? ""} does nnot have access to this tool`
            );
          }
        } catch (error) {
          console.log(error);
          window.alert(error);
        }
      }
    },
    onError: (
      error: Pick<TokenResponse, "error" | "error_description" | "error_uri">
    ) => {
      props.onLoginFail(error.toString());
    },
  });

  const login = async () => {
    try {
      setProcessing(true);
      await loginWithGoogle();
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      console.log("Login Failed:", error);
      window.alert(error);
    }
  };
  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div
          className="surface-section w-full md:w-6"
          style={{
            padding: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="w-full mb-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Entrar com Google"
              icon="pi pi-google"
              iconPos="right"
              onClick={login}
            />
          </div>
          {processing && (
            <>
              <div className="w-full">
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              </div>
            </>
          )}
        </div>
        <div
          className="hidden md:block w-6 bg-no-repeat bg-center"
          style={{
            backgroundSize: "180px",
            backgroundColor: "#5371ff",
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/diwo-negative.png')`,
          }}
        ></div>
      </div>
    </>
  );
}
