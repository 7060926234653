/* eslint-disable react/react-in-jsx-scope */
import {
  ButtonWrapper,
  Group,
  InputTextStyled,
  InputTextareaStyled,
  Wrapper,
} from "./EditScenario.style";
import { Button } from "primereact/button";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ScenarioService } from "../../../_shared/services/ScenarioService";
import { useNavigate, useParams } from "react-router-dom";
import { Scenario, ScenarioStatus } from "../../../_shared/types/types";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import MonacoEditor from "@monaco-editor/react";

const EditScenario = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState<boolean>(false);
  const [jsonError, setJsonError] = useState(false);
  const [scenario, setScenario] = useState<Scenario>({
    subscriptionId: "",
    customer: "",
    instance_name: "",
    scenario_id: "",
    scenario_name: "",
    status: ScenarioStatus.InstanceDisconnected,
  });
  const { id, scenarioId } = useParams();

  const toast: any = useRef(null);

  useEffect(() => {
    setProcessing(false);
    const findScenario = async (): Promise<Scenario> => {
      let data;
      if (scenarioId) {
        data = await new ScenarioService().getScenario(scenarioId);
      }
      return data;
    };

    findScenario().then((response) =>
      setScenario({
        ...response,
        custom_info: response.custom_info
          ? JSON.stringify(response.custom_info, undefined, 2)
          : "",
        followup_prompts: response.followup_prompts
          ? JSON.stringify(response.followup_prompts, undefined, 2)
          : "",
        classification_prompts: response.classification_prompts
          ? JSON.stringify(response.classification_prompts, undefined, 2)
          : "",
      })
    );
  }, []);

  const handleChange = (
    event:
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | InputNumberValueChangeEvent
      | InputSwitchChangeEvent
  ) => {
    const { name, value } = event.target;
    setScenario({ ...scenario, [name]: value });
  };

  const handleJsonChange = (name: string, value: string | undefined) => {
    if (value !== undefined) {
      setScenario({ ...scenario, [name]: value });
      try {
        JSON.parse(value);
        setJsonError(false);
      } catch (error) {
        setJsonError(true);
      }
    }
  };

  const isValidJSON = (event: any): boolean => {
    const { ariaLabel, value } = event.target;
    if (value === "") {
      return true;
    }

    try {
      JSON.parse(value);
      setJsonError(false);
      return true;
    } catch (error) {
      setJsonError(true);
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: `O campo ${ariaLabel} não é um JSON válido`,
      });
      return false;
    }
  };

  const cancel = async () => {
    navigate(`/${id}`);
  };

  const save = async () => {
    setProcessing(true);
    if (jsonError) {
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: "Verifique os campos JSON",
      });
      setProcessing(false);
      return;
    }

    return new ScenarioService()
      .saveScenario(scenario.scenario_id, scenario)
      .then((response) => {
        console.log({ response });

        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Cenário atualizado com sucesso",
        });
      })
      .catch((error) => {
        console.error({ error });

        toast?.current?.show({
          severity: "error",
          summary: "Ooops...",
          detail: error.message,
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column flex-nowrap w-full m-5">
        <Wrapper>
          <div className="font-medium text-900 flex flex-column mb-2 ">
            <div className="font-bold flex align-items-center mb-2">
              Customer:
            </div>
            <div className="w-full mb-2">
              <InputTextStyled
                name="customer"
                value={scenario?.customer}
                onChange={handleChange}
                disabled
              />
            </div>
          </div>

          <Group>
            <div className="font-medium text-900 flex flex-column mb-2 ">
              <div className="font-bold flex align-items-center mb-2">
                Scenario:
              </div>
              <div className="w-full mb-2">
                <InputTextStyled
                  name="scenario_name"
                  value={scenario?.scenario_name}
                  onChange={handleChange}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="font-medium text-900 flex flex-column mb-2 ">
              <div className="font-bold flex align-items-center mb-2">
                Instância:
              </div>
              <div className="w-full mb-2">
                <InputTextStyled
                  name="instance_name"
                  value={scenario?.instance_name}
                  onChange={handleChange}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </Group>

          <Group>
            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Is Trash:
              </div>
              <div className="w-full mb-2">
                <InputSwitch
                  name="istrash"
                  checked={scenario?.istrash ?? false}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Sleep Time Bot:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="sleep_time_bot"
                  showButtons
                  value={scenario?.sleep_time_bot}
                  onValueChange={handleChange}
                  useGrouping={false}
                />
              </div>
            </div>
          </Group>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              First Message Prompt:
            </div>
            <div className="w-full mb-2">
              <InputTextareaStyled
                name="first_message_prompt"
                value={scenario?.first_message_prompt}
                onChange={handleChange}
                rows={10}
              />
            </div>
          </div>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Prompt:
            </div>
            <div className="w-full mb-2">
              <InputTextareaStyled
                name="prompt"
                value={scenario?.prompt}
                rows={10}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Internal Prompt:
            </div>
            <div className="w-full mb-2">
              <InputTextareaStyled
                name="internal_prompt"
                value={scenario?.internal_prompt}
                rows={10}
                onChange={handleChange}
              />
            </div>
          </div>

          <Group>
            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Prompt Temperature:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="prompt_temperature"
                  showButtons
                  value={scenario?.prompt_temperature}
                  onValueChange={handleChange}
                />
              </div>
            </div>

            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Prompt Max Tokens:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="prompt_maximum_length"
                  showButtons
                  value={scenario?.prompt_maximum_length}
                  onValueChange={handleChange}
                />
              </div>
            </div>
          </Group>

          <Group>
            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Prompt Top P:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="prompt_top_p"
                  showButtons
                  value={scenario?.prompt_top_p}
                  onValueChange={handleChange}
                />
              </div>
            </div>

            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Model:
              </div>
              <div className="w-full mb-2">
                <InputTextStyled
                  name="model"
                  value={scenario?.model}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Group>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Notification E-mail:
            </div>
            <div className="w-full mb-2">
              <InputTextStyled
                name="notification_email"
                value={scenario?.notification_email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Custom Info:
            </div>
            <div
              className="w-full mb-2"
              style={{ height: "300px", border: "1px solid #ccc" }}
            >
              <MonacoEditor
                height="100%"
                defaultLanguage="json"
                value={scenario.custom_info as unknown as string}
                onChange={(value) => handleJsonChange("custom_info", value)}
              />
            </div>
            {jsonError && <div className="text-red-500">JSON inválido</div>}
          </div>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Follow Up Prompts:
            </div>
            <div className="w-full mb-2" style={{ border: "1px solid #ccc" }}>
              <MonacoEditor
                height="300px"
                defaultLanguage="json"
                value={scenario.followup_prompts as unknown as string}
                onChange={(value) =>
                  handleJsonChange("followup_prompts", value)
                }
              />
            </div>
          </div>

          <div className="font-medium text-900 flex flex-column mb-2">
            <div className="font-bold flex align-items-center mb-2">
              Classification Prompts:
            </div>
            <div className="w-full mb-2" style={{ border: "1px solid #ccc" }}>
              <MonacoEditor
                height="300px"
                defaultLanguage="json"
                value={scenario.classification_prompts as unknown as string}
                onChange={(value) =>
                  handleJsonChange("classification_prompts", value)
                }
              />
            </div>
          </div>

          <Group>
            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Prompt Memory Window:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="prompt_memory_window"
                  showButtons
                  value={scenario?.prompt_memory_window}
                  onValueChange={handleChange}
                />
              </div>
            </div>

            <div className="font-medium text-900 flex flex-column mb-2">
              <div className="font-bold flex align-items-center mb-2">
                Prompt Max Messages:
              </div>
              <div className="w-full mb-2">
                <InputNumber
                  name="prompt_max_messages"
                  showButtons
                  value={scenario?.prompt_max_messages}
                  onValueChange={handleChange}
                />
              </div>
            </div>
          </Group>
        </Wrapper>

        <ButtonWrapper>
          <Group>
            <Button
              disabled={processing}
              label="Cancelar"
              icon="pi pi-times"
              onClick={cancel}
              className="p-button-text"
            />
            <Button
              disabled={processing}
              label={processing ? "Salvando..." : "Salvar"}
              icon={`pi ${processing ? "pi-spin pi-spinner" : "pi-check"}`}
              onClick={save}
            />
          </Group>
        </ButtonWrapper>
      </div>
    </>
  );
};

export default EditScenario;
