import moment from "moment";
import EntityList from "../../_shared/components/EntityList/EntityList";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { DataCaptureProcessesService } from "../../_shared/services/DataCaptureProcessesService";
import { Tag } from "primereact/tag";
import { AddDataCaptureProcess } from "../components/AddDataCaptureProcessV2";
import {
  DataCaptureProcess,
  DataCaptureProcessStatus,
} from "../../_shared/types/types";

export default function DataCaptureProcesses() {
  const addComponent = (
    entityInstance: any,
    onEntityUpdate: (updatedEntityInstance: any) => void
  ) => {
    return (
      <AddDataCaptureProcess
        processToEdit={entityInstance as DataCaptureProcess}
        onUpdate={onEntityUpdate}
      />
    );
  };

  const start = async () => {
    const service = new DataCaptureProcessesService();
    await service.start();
  };

  const retry = async (dataCaptureProcess: DataCaptureProcess) => {
    const service = new DataCaptureProcessesService();
    await service.retry(dataCaptureProcess);
  };

  return (
    <>
      <EntityList
        title="Data Capture Processes"
        entity="maplead_jobs.data_capture_processes"
        entityLabel="Data Capture Process"
        defaultSortField="created_on"
        defaultSortOrder={-1}
        createDisabled={false}
        editDisabled={true}
        hideDetailsButton={true}
        columns={[
          {
            title: "Process ID",
            sortable: true,
            sortField: "id",
            body: "id",
          },
          {
            title: "Search Phrase",
            body: "category",
            sortable: true,
            sortField: `category`,
          },
          {
            title: "State",
            body: "state",
            sortable: true,
            sortField: `state`,
          },
          {
            title: "City",
            body: "city",
            sortable: true,
            sortField: `city`,
          },
          {
            title: "Allocated Memory",
            body: "allocated_memory",
            sortable: true,
            sortField: `allocated_memory`,
          },
          {
            title: "Status",
            body: (item: DataCaptureProcess) => (
              <Tag
                severity={
                  item.status === DataCaptureProcessStatus.Finished
                    ? "success"
                    : item.status === DataCaptureProcessStatus.Error
                    ? "danger"
                    : "info"
                }
              >
                {item.status?.toUpperCase().replaceAll("_", " ")}
              </Tag>
            ),
            sortable: true,
            sortField: `status`,
          },
          {
            title: "Run ID",
            sortable: true,
            sortField: "runid",
            body: "runid",
          },
          {
            title: "Record Count",
            sortable: true,
            sortField: "record_count",
            body: "record_count",
          },
          {
            title: "Created On",
            sortable: true,
            sortField: "created_on",
            body: (item: DataCaptureProcess) =>
              moment(item.created_on).format("YYYY-MM-DD HH:mm"),
          },
          {
            title: "Last Update On",
            sortable: true,
            sortField: "last_updated_on",
            body: (item: DataCaptureProcess) =>
              moment(item.last_updated_on).format("YYYY-MM-DD HH:mm"),
          },
          {
            title: "Finished On",
            sortable: true,
            sortField: "finished_on",
            body: (item: DataCaptureProcess) =>
              item.finished_on
                ? moment(item.finished_on).format("YYYY-MM-DD HH:mm")
                : "-",
          },
        ]}
        search={["id", "runid", "state", "city", "category", "status"]}
        AddComponent={addComponent}
        extraButtons={[
          {
            icon: "pi pi-play",
            label: "Start",
            perItem: false,
            onClick: start,
            className: "ml-0 mt-1 md:ml-2 md:mt-0",
          },
          {
            icon: "pi pi-replay",
            label: "Retry",
            perItem: true,
            onClick: retry,
            className: "ml-0 mr-1 md:mr-2 md:mt-0",
            showIf: (item: DataCaptureProcess) => [
              DataCaptureProcessStatus.StoringData,
              DataCaptureProcessStatus.DataStored,
              DataCaptureProcessStatus.Geocoded,
              DataCaptureProcessStatus.Geocoding,
              DataCaptureProcessStatus.CheckingWhatsapp,
              DataCaptureProcessStatus.WhatsappChecked
            ].includes(item.status),
          },
        ]}
      />
    </>
  );
}
