import { GoogleUser } from "../admin/types/types";

export const saveUserToLocalStorage = (user: GoogleUser) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("currentUser");
};

export const getUserFromLocalStorage = (): GoogleUser | undefined => {
  const userData: string | null = localStorage.getItem("currentUser");
  if (userData) {
    return JSON.parse(userData);
  }
  return undefined;
};

export const getAdminUserFromLocalStorage = (): GoogleUser | undefined => {
  const userData: string | null = localStorage.getItem("adminUser");
  if (userData) {
    return JSON.parse(userData);
  }
  return undefined;
};
