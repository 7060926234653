import "./App.css";
import "primeflex/primeflex.css";
import "./theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Header from "../_shared/components/Header";
import LoginScreen from "./screens/Login";
import ScenariosScreen from "./screens/Scenarios";
import { ProgressBar } from "primereact/progressbar";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { Subscription } from "../_shared/types/types";
import { SubscriptionsService } from "../_shared/services/SubscriptionsService";
import BillingScreen from "./screens/Billing";
import MessagesByPhone from "../admin/screens/MessagesByPhone";
import CreditStatement from "./screens/CreditStatement";
import React from "react";

const AppProps = {
  logo: "/images/diwo-negative.png",
};

function App() {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState<Subscription | undefined>(
    undefined
  );

  useEffect(() => {
    const loadSubscription = async () => {
      const id: string = window.location.pathname
        .split("/")
        .filter((s) => s !== "")[0];
      if (id) {
        try {
          const _subscription: Subscription =
            await new SubscriptionsService().getSubscriptionByCode(id);
          if (_subscription) {
            setSubscription(_subscription);            
            return;
          }
        } catch (error) {
          console.log(error);
        }
      }
      else {
        setSubscription({
          id: "",
          name: "",
        });
        navigate("/");
      }
      
    };

    loadSubscription();
  }, [navigate]);

  const updateSubscription = async (_subscription: Subscription) => {
    setSubscription(_subscription);
  };

  const internalScreen = (breadcrumb: string[], screen: ReactNode) => {
    if (subscription) {
      return (
        <div className="min-h-screen flex flex-column">
           { !fullScreen && <Header
            logo={AppProps.logo}
            breadcrumb={breadcrumb}
            user={subscription}
            onBack={window.location.pathname === `/${subscription.id}` || window.location.pathname === `/${subscription.id}/scenarios`  ? undefined : () => navigate(`/${subscription.id}`)}
          />}
          <div
            className={`p-5 flex flex-column flex-auto ${fullScreen ? 'bg-primary-500' : '' }`}
            style={fullScreen ? {} : { marginTop: "-8rem"}}
          >
            <div className="surface-card shadow-2 border-round flex flex-auto">
              {screen}
            </div>
          </div>
        </div>
      );
    }
  };

  const [fullScreen, setFullScreen] = useState<boolean>(false)

  if (subscription) {
    return (
      <Routes>
        <Route
          path="/"
          element={<LoginScreen onSubscriptionUpdate={updateSubscription} />}
        />
        <Route
          path="/:id"
          element={internalScreen(
            ["Créditos e Cenários"],
            <ScenariosScreen
              subscription={subscription}
              onSubscriptionUpdate={updateSubscription}
            />
          )}
        />
        <Route
          path="/:id/scenarios"
          element={internalScreen(
            ["Créditos e Cenários"],
            <ScenariosScreen
              subscription={subscription}
              onSubscriptionUpdate={updateSubscription}
            />
          )}
        />
        <Route
          path="/:id/billing"
          element={internalScreen(["Detalhamento de Consumo"], <BillingScreen subscription={subscription}/>)}
        />
        <Route
          path="/:id/statement"
          element={internalScreen(["Detalhamento de Consumo"], <CreditStatement subscription={subscription}/>)}
        />
         <Route
          path="/:subscriptionId/scenarios/:scenarioId/zap"
          element={internalScreen(
            ["Whatsapp Web"],
            <MessagesByPhone fullScreen={fullScreen} setFullScreen={setFullScreen}/>
          )}
        />
      </Routes>

      
    );
  } else {
    return (
      <div
        className="hidden md:block w-12 bg-no-repeat bg-center"
        style={{
          height: "100vh",
          backgroundSize: "100px",
          backgroundColor: "#5371ff",
          backgroundImage: `url('${process.env.PUBLIC_URL}/images/diwo-negative.png')`,
        }}
      >
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      </div>
    );
  }
}

export default App;
