import React from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { City, DataCaptureProcess, State } from "../../_shared/types/types";
import { EntityService } from "../../_shared/services/EntityService";
import { DataProcessService } from "../../_shared/services/DataProcessService";

type SearchPhrase = {
  searchphrase: string;
};

export function AddDataCaptureProcess(props: {
  processToEdit: DataCaptureProcess;
  onUpdate: (processToEdit: DataCaptureProcess) => void;
}) {
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [languages, setLanguages] = useState<State[]>([]);
  const [loadingSearchPhrases, setLoadingSearchPhrases] =
    useState<boolean>(false);
  const [searchPhrases, setSearchPhrases] = useState<SearchPhrase[]>([]);

  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);

  const loadLanguages = async () => {
    setLoadingLanguages(true);
    setLanguages(await new EntityService("maplead_jobs.languages").listAll());
    setLoadingLanguages(false);
  };

  const loadSearchPhrases = async () => {
    setLoadingSearchPhrases(true);
    const searchPhrasesLoaded = await new DataProcessService().getDataProcess(
      ""
    );
    setSearchPhrases(searchPhrasesLoaded);
    setLoadingSearchPhrases(false);
  };

  const loadCities = async (searchKey: string) => {
    setLoadingCities(true);
    setCities(
      (
        await new EntityService("cities").list(
          1,
          100,
          `cidade`,
          1,
          `cidade=${searchKey}`
        )
      ).items
    );
    setLoadingCities(false);
  };

  useEffect(() => {
    loadLanguages();
    loadSearchPhrases();
  }, []);

  const [searchText, setSearchText] = useState<string>("");
  const search = (searchKey: string) => {
    setCities([]);
    loadCities(searchKey);
  };

  return (
    <div
      className="w-full"
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        minWidth: "400px",
      }}
    >
      <div className="font-medium text-900 flex flex-column mb-2">
        <div className="font-bold flex align-items-center mb-2">
          Search Phrase:
        </div>
        <div
          className="w-full mb-2"
          style={{ maxHeight: "200px", overflow: "auto" }}
        >
          {loadingSearchPhrases ? (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          ) : (
            <Dropdown
              value={props.processToEdit?.category ?? ""}
              onChange={(e) => {
                props.onUpdate(
                  Object.assign(
                    {},
                    {
                      ...props.processToEdit,
                      category: e.value,
                    }
                  )
                );
              }}
              options={searchPhrases.map((searchPhrase: SearchPhrase) => ({
                id: searchPhrase.searchphrase,
                name: searchPhrase.searchphrase,
              }))}
              optionValue="id"
              optionLabel="name"
              placeholder="Select a Search Phrase"
              style={{ width: "100%" }}
              editable
            />
          )}
        </div>
        <div className="font-bold flex align-items-center mb-2">Language:</div>
        <div
          className="w-full mb-2"
          style={{ maxHeight: "200px", overflow: "auto" }}
        >
          {loadingLanguages ? (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          ) : (
            <Dropdown
              value={props.processToEdit.language}
              onChange={(e) => {
                props.onUpdate(
                  Object.assign(
                    {},
                    {
                      ...props.processToEdit,
                      language: e.value,
                    }
                  )
                );
              }}
              options={languages.map((language) => ({
                id: language.id,
                name: `${language.name} (${language.id})`,
              }))}
              optionValue="id"
              optionLabel="name"
              placeholder="Select a Language"
              style={{ width: "100%" }}
              filter
            />
          )}
        </div>
        <div className="font-bold flex align-items-center mb-2">Cities:</div>
        <div className="w-full mb-2">
          <span className="p-input-icon-left w-full">
            {loadingCities ? (
              <i className="pi pi-spin pi-spinner" />
            ) : (
              <i className="pi pi-search" />
            )}
            <InputText
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search(searchText);
                }
              }}
              className="w-full"
              placeholder="Search by city name"
            />
          </span>
          <div
            style={{
              width: "100%",
              height: "150px",
              border: "1px solid #dddddd",
              padding: "10px",
              overflow: "scroll",
              borderRadius: "8px",
            }}
          >
            {cities
              .filter(
                (city) =>
                  !(props.processToEdit.cities ?? [])
                    .map((c) => c.id)
                    .includes(city.id)
              )
              .map((city: City) => (
                <div>
                  <div className="flex align-items-center text-normal p-2 mb-2">
                    <div>
                      {city.name}, {city.state ?? city.state_name} -{" "}
                      {city.country_name}
                      {city.population
                        ? " (pop.: " +
                          (city.population / 1000).toFixed(2) +
                          "k)"
                        : ""}
                    </div>
                    <div
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        if (
                          !(props.processToEdit.cities ?? []).find(
                            (c) => city.id === c.id
                          )
                        ) {
                          props.onUpdate(
                            Object.assign(
                              {},
                              {
                                ...props.processToEdit,
                                cities: (
                                  props.processToEdit.cities ?? []
                                ).concat([city]),
                              }
                            )
                          );
                        }
                      }}
                    >
                      <i className="pi pi-plus-circle text-green-500"></i>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-wrap mt-2">
            {(props.processToEdit.cities ?? []).map((city: City) => (
              <>
                <div className="flex align-items-center text-normal p-2 surface-200 border-round-xl mr-2 mb-2">
                  <div>
                    {city.name}, {city.state ?? city.state_name} -{" "}
                    {city.country_name}
                  </div>
                  <div
                    className="ml-2 cursor-pointer"
                    onClick={() => {
                      props.onUpdate(
                        Object.assign(
                          {},
                          {
                            ...props.processToEdit,
                            cities: (props.processToEdit.cities ?? []).filter(
                              (c) => city.id !== c.id
                            ),
                          }
                        )
                      );
                    }}
                  >
                    <i className="pi pi-times-circle text-red-500"></i>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
