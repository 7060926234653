import { ProgressBar } from "primereact/progressbar";

export default function Loading() {
  return (
    <div
      className="hidden md:block w-12 bg-no-repeat bg-center"
      style={{
        height: "100vh",
        backgroundSize: "100px",
        backgroundColor: "#5371ff",
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/diwo-negative.png')`,
      }}
    >
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    </div>
  );
}
