/* eslint-disable react/react-in-jsx-scope */
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from "react";
import { Subscription } from "../../_shared/types/types";
import { SubscriptionsService } from "../../_shared/services/SubscriptionsService";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

export default function Subscriptions() {
  const toast: any = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    const load = async () => {
      setSubscriptions(await new SubscriptionsService().list());
      setLoading(false);
    };

    load();
  }, []);

  const [showCreateSubscription, setShowCreateSubscription] =
    useState<boolean>(false);
  const [dealId, setDealId] = useState<string>("");
  const createSubscription = async () => {
    try {
      setProcessing(true);
      await new SubscriptionsService().create(dealId);
      setProcessing(false);
      setShowCreateSubscription(false);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail:
          "Subscription creation requested. Please refresh this page after sometime to check.",
      });
    } catch (error: any) {
      setProcessing(false);
      toast?.current?.show({
        severity: "error",
        summary: "Ooops...",
        detail: error.message,
      });
    }
  };

  const cancel = async (subscription: Subscription) => {
    confirmDialog({
      message: `Do you really want to cancel subscription ${subscription.name} ?`,
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          setProcessing(true);
          await new SubscriptionsService().cancel(subscription.id);
          setProcessing(false);
          toast?.current?.show({
            severity: "success",
            summary: "Success",
            detail:
              "Subscription cancellation requested. Please refresh this page after sometime to check.",
          });
        } catch (error: any) {
          setProcessing(false);
          toast?.current?.show({
            severity: "error",
            summary: "Ooops...",
            detail: error.message,
          });
        }
      },
    });
  };

  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const statuses = subscriptions.filter(
    (value, index, self) =>
      self.findIndex((t) => t.status === value.status) === index
  );

  const getSeverity = (status: any) => {
    switch (status) {
      case "active":
        return "success";
      case "pending_payment":
        return "warning";
      default:
        return "info";
    }
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses.map((s) => s.status)}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Filter by Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const statusItemTemplate = (option: any) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="p-4">
        <div className="flex justify-content-between align-items-center mb-5">
          <div className="text-xl text-900 font-medium">Subscriptions</div>
          <div>
            <Button
              label="Create"
              icon="pi pi-plus"
              onClick={() => setShowCreateSubscription(true)}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
        {processing && (
          <>
            <div className="w-full mb-2">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            </div>
          </>
        )}
        <DataTable
          loading={loading}
          value={subscriptions}
          tableStyle={{ minWidth: "50rem" }}
          paginator
          rows={20}
          rowsPerPageOptions={[20, 50, 100]}
          filters={filters}
          filterDisplay="row"
        >
          <Column
            field="name"
            header="Name"
            filter
            filterPlaceholder="Search by name"
          ></Column>
          <Column
            body={(subscription: Subscription) => (
              <Tag severity={getSeverity(subscription.status)}>
                {subscription.status?.toUpperCase()}
              </Tag>
            )}
            header="Status"
            field="status"
            showFilterMenu={false}
            filter
            filterElement={statusRowFilterTemplate}
          ></Column>
          <Column
            header={"Actions"}
            body={(subscription: Subscription) => (
              <>
                <Button
                  className="p-button-text p-button-plain p-button-rounded mr-1"
                  label="View"
                  icon="pi pi-external-link"
                  disabled={loading}
                  onClick={() => window.open(`/${subscription.id}`, "new")}
                />
                <Button
                  className="p-button-text p-button-plain p-button-rounded mr-1"
                  label="Cancel"
                  icon="pi pi-trash"
                  disabled={loading}
                  onClick={() => cancel(subscription)}
                />
              </>
            )}
          ></Column>
        </DataTable>
      </div>
      {showCreateSubscription && (
        <>
          <Dialog
            header={"Create Subscription"}
            visible={true}
            onHide={() => {
              setDealId("");
              setShowCreateSubscription(false);
            }}
            footer={
              <div>
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  onClick={() => {
                    setDealId("");
                    setShowCreateSubscription(false);
                  }}
                  className="p-button-text"
                />
                <Button
                  label="Salvar"
                  icon="pi pi-check"
                  onClick={() => createSubscription()}
                  autoFocus
                />
              </div>
            }
          >
            <div
              className="w-full"
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                minWidth: "400px",
              }}
            >
              <div className="font-medium text-900 flex flex-column mb-2">
                <div className="font-bold flex align-items-center mb-2">
                  Deal ID:
                </div>
                <div className="w-full mb-2">
                  <InputText
                    value={dealId ?? ""}
                    onChange={(e) => setDealId(e.target.value)}
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
