import { BaseService } from "./BaseService";

export class DataProcessService extends BaseService {
  constructor() {
    super();
    this.path = "/data-processes/";
  }

  getDataProcess = async (searchPhrase: string) => {
    return await this.get(`${searchPhrase}`);
  };

  saveDataProcess = async (searchPhrase: string, data: string) => {
    return await this.post(`${searchPhrase}`, data);
  };
}
