import { Instance } from "../../admin/types/types";
import { BaseService } from "./BaseService";

export class MessagesService extends BaseService {
    
    constructor(){
        super()
        this.path = '/messages'
    }

    send = async (message: string, to: string, instance: string) : Promise<void> => {
        await this.post("/", { message, to, instance })
        return
    }
}